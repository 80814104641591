app.controller('operationsController', [
  '$rootScope',
  '$timeout',
  '$parse',
  'componentsService',
  'globalService',
  'intercomService',
  'loaderService',
  'navigationService',
  function(
    $rootScope,
    $timeout,
    $parse,
    componentsService,
    globalService,
    intercomService,
    loaderService,
    navigationService
  ) {
    componentsService.init();
    navigationService.init();
    loaderService.init();
    intercomService.init();
    globalService.init();

    /** **************************** */
    /** ******** VARIABLES ********* */
    /** **************************** */

    /** **************************** */
    /** ******** FUNCTIONS ********* */
    /** **************************** */

    /** ************************************** */
    /** ******** ROOTSCOPE VARIABLES ********* */
    /** ************************************** */
    window.SHOW_ADD_PROPERTY = true;
    $rootScope.DEMO_MODE = !!window.DEMO_MODE;
    $rootScope.SHOW_ADD_PROPERTY = !!window.SHOW_ADD_PROPERTY;

    /** *********************** */
    /** ******** INIT ********* */
    /** *********************** */

    /** ************************************** */
    /** ******** ROOTSCOPE FUNCTIONS ********* */
    /** ************************************** */

    $rootScope.errorReload = () => {
      window.location.reload();
    };

    /**
     * This function determines if the size of the background image should be determined by its height or by its width.
     * @param {{}} $bg - The background image by jQuery means.
     * @param {number} aspectRatio - The ratio of the background image width over its height
     */
    $rootScope.$resizeBg = ($bg, aspectRatio) => {
      const window1 = $(window);
      if (window1.width() / window1.height() < aspectRatio) {
        $bg.removeClass('bgwidth').addClass('bgheight');
      } else {
        $bg.removeClass('bgheight').addClass('bgwidth');
      }
    };

    $rootScope.$smoothScrollTo = (element, offset) => {
      const offsetTop = $(element).offset().top + (offset || 0);
      $(document.body).animate(
        {
          scrollTop: offsetTop
        },
        500
      );
    };

    $rootScope.$smoothScrollToWithin = (element, within, offset) => {
      const offsetTop = $(element).position().top + $(within).scrollTop() + (offset || 0);
      $(within).animate(
        {
          scrollTop: offsetTop
        },
        500
      );
    };

    /**
     * Dynalicaly creates a $rootScope variable based off of the search input's name.
     * @param {{}} element - Search Input
     * @param {boolean} value - true = set value to '' && false = set value to input value
     */
    $rootScope.$assignRootScopeVar = (element, value) => {
      const elementName = $parse(element.name);
      $timeout(() => {
        if (!value) {
          elementName.assign($rootScope, '');
        } else {
          elementName.assign($rootScope, element.value);
        }
      });
    };

    /**
     * Determines and sets the max-height for a given type section
     * @param {string} type
     * @param {boolean} oneColumn
     */
    $rootScope.$assignMaxHeight = (type, oneColumn) => {
      let length = $rootScope.adjustedFilter.initFilterHash[type].length;
      let heightLength = length;

      if (oneColumn) {
        heightLength = length;
      } else if (length % 2 !== 0) {
        /** Length is odd. */
        length += 1;
        heightLength = length / 2;
      } else {
        /** Length is even. */
        heightLength = length / 2;
      }

      /** The 33 is the px height of each options. */
      heightLength = 33 * heightLength + 49;

      /** the 240 is the 15rem. The maximum height for a type section. */
      if (heightLength > 250) {
        heightLength = 250;
      }

      const name = `${type}_height`;
      $rootScope.adjustedFilter[name] = { 'max-height': `${heightLength}px` };
    };

    $rootScope.$propertySearch = (open, type) => {
      const element = document.getElementById('search-bar');

      if (open && $rootScope.activeSidebar !== type) {
        $rootScope.$clearInput(null, element);
        $rootScope.activeSidebar = type;
        $rootScope.overflowBody = true;
      } else {
        $rootScope.activeSidebar = null;
        $rootScope.overflowBody = false;
      }
    };

    $rootScope.$propertyOffers = (open, type) => {
      if (open && $rootScope.activeSidebar !== type) {
        $rootScope.activeSidebar = type;
        $rootScope.overflowBody = true;
        if ($rootScope.$newPropertyOffersCount > 0) {
          // TODO: call endpoint to mark new property offers as viewed
          $rootScope.$newPropertyOffersCount = 0;
        }
      } else {
        $rootScope.activeSidebar = null;
        $rootScope.overflowBody = false;
      }
    };

    $rootScope.$calcOfferROILevel = (roi) => {
      if (roi < 10) {
        return 0;
      }
      else if (roi < 30) {
        return 1;
      }
      else {
        return 2;
      }
    };

    $rootScope.$orgOffers = [
      {
        id: "1",
        title: "Hitachi Smart Flex VRF (Variable Refrigerant Flow) System",
        property_id: "4735834A-F454-EB11-9889-0003FFB4C1E1",
        property_name: "1472 Cathedral Lane",
        est_annual_savings: 54000,
        capital_costs: 687000,
        payback_period: 2.5,
        roi: 45,
        roi_level: $rootScope.$calcOfferROILevel(45),
        description: "The proposed Hitachi VRF system Hitachi SmartFlex™ VRF builds upon the game-changing innovations that make variable refrigerant flow systems the most flexible and energy-efficient HVAC solutions in the world. With an expanded, re-engineered equipment line and new, groundbreaking technology, Hitachi SmartFlex VRF offers greater performance, more design freedom, new business opportunities and exceptional control.\n",
        financial_details: {
          items: [
            { 'title': 'Equipment & Labour Costs', 'description': '- Equipment Costs: $280,000\n- Labour Costs: $407,000\n- Total Cost: $687,000' },
            { 'title': 'Operational Costs (Entire Building/year)', 'description': '- Heating: $29,600\n- Cooling: $9,500\n- Total Cost: $39,100' },
            { 'title': 'Efficiency NS Rebates (Entire Building)', 'description': '$36,000 - $79,200 range' }
          ]
        },
        technical_details: {
          items: [
            { 'title': 'Baseline Operating Comparison', 'description': 'Baseline Scenario - Propane Fired Aerco Boiler Hot Water Baseboard (Heating) w/ Fujitsu Mini Splits (Cooling, 12,000 BTUs each)\n\nEquipment & Labour Costs\n- AERCO Boiler: $80,000\n-	Fujitsu Mini Splits: $136,000\n- Labour Costs: $338,000\n- Total Cost: $554,000\n\nOperational Costs (Entire Building/year)\n- Heating: $82,000\n- Cooling: $11,000\n- Total Cost: $93,000\n\nEfficiency NS Rebates (Entire Building)\nNone or very little' },
            { 'title': 'Assumptions', 'description': 'These conclusions are based on RETScreen archetype models of multi residential buildings in our climate zone. The domestic electricity tariff of $0.15805 / kWh was used and a propane price of 70 cents/litre was used.' },
            { 'title':'Observations', 'description': '- This system is still common but out of date.\n- Lower capital costs but significantly higher operating costs.\n- No Efficiency NS rebates.\n- Mini split units on patios take up space, make noise and can be considered an eyesore.  However, no regular maintenance required in the suite during heating season.\n- It is also important to note that neither VRF or hot water baseboard systems are used for parking garage heating. If heating is required in the parking garage then additional unit heaters would need to be installed.\n- 3 year warranty on Fujitsu mini splits and 10 year warranty on Aerco boiler.\n- Boiler system is easy to run off a generator is there is a power outage.' },
            { 'title':'Conclusions', 'description': 'VRF system is an ideal premium, efficient HVAC system. The ENS rebate will help with offsetting the additional VRF capital costs. Killam would potentially see a saving approximately $50,000 a year in operational costs vs. the hot water baseboard system which means the payback for the premium VRF system would be approximately 2.5 years and that is without additional federal funding. It is also important to note that adding heat recovery or back up heat will increase the costs and add time to the payback period.' }
          ]
        }
      }
    ];

    $rootScope.$getPCMessage = () => {
      if (!$rootScope.property || !$rootScope.property.profile) {
        return "";
      }
      const canEdit = !$rootScope.property || $rootScope.property.access_level >= 2;
      if ($rootScope.property.profile.completeness < 75) {
        if (canEdit) {
          return "A high Profile Completeness Score for a building helps get the most out of Building360! Here are some steps to increase it:";
        }
        else {
          return "A high Profile Completeness Score for a building helps get the most out of Building360! Ask someone with permission to Edit this building’s info to add more details.";
        }
      }
      else if ($rootScope.property.profile.completeness < 100) {
        if (canEdit) {
          return "Adding a bit more info will get you a perfect Profile Completeness Score for this building! Here are some steps to increase it:";
        }
        else {
          return "Adding a bit more info will get a perfect Profile Completeness Score for this building! Ask someone with permission to Edit this building’s info to add more details.";
        }
      }
      else {
        if (canEdit) {
          return "Your building’s profile is completely filled out - great job!";
        }
        else {
          return "This building’s profile is completely filled out!";
        }
      }
      return "";
    }

    $rootScope.$newPropertyOffersCount = $rootScope.$orgOffers.length;
  }
]);
