app.service('storageService', [
  '$rootScope',
  '$analytics',
  '$q',
  '$timeout',
  '$http',
  'usersService',
  function($rootScope, $analytics, $q, $timeout, $http, usersService) {
    // FRONTEND: Clear out the // and replace them with /**  */

    const LOGIN_CHECK_INTERVAL_SECONDS = 15;

    const storageService = this;
    /** ************************************ */
    /** ******** PROMISE FUNCTIONS ********* */
    /** ************************************ */
    const parseAsync = Promise.method(angular.fromJson);
    const stringifyAsync = Promise.method(angular.toJson);

    /** ************************************ */
    /** ******** SERVICE FUNCTIONS ********* */
    /** ************************************ */
    /**
     * Get the storage key value that is save in sessionStorage
     *
     * @param {String} key - Name of the key that you are searching for
     */
    this.get = function(key) {
      $http.defaults.headers.common.Authorization = $rootScope.token && $rootScope.token;

      try {
        // Automatically determining where to get the information
        if (localStorage.rememberMe) {
          return parseAsync(localStorage[key]);
        }
        return parseAsync(sessionStorage[key]);
      } catch (err) {
        return undefined;
      }
    };

    this.getSync = function(key) {
      if (localStorage.rememberMe) {
        return localStorage[key] ? parseAsync(localStorage[key]) : null;
      }
      return sessionStorage[key] || null;
    };

    /**
     * Saves information into sessionStorage
     * @param {String} key - The name of the key to save in sessionStorage
     * @param {Object} value - The value that is being saved
     * @return {Object} - Returns the value object
     */
    this.set = function(key, value) {
      return stringifyAsync(value).then((stringifiedValue) => {
        // Save the value to the key
        sessionStorage[key] = stringifiedValue;

        // Sync the $rootScope object
        $rootScope[key] = value;
        $http.defaults.headers.common.Authorization = $rootScope.token && $rootScope.token;
        return value;
      });
    };

    this.getTokenCookie = function() {
      const cookies = document.cookie.split('; ');
      for (let i = 0; i < cookies.length; i++) {
        const parts = cookies[i].split('=');
        if (parts[0] === 'token' && parts[1]) {
          return parts[1];
        }
      }
      return null;
    };

    this.removeTokenCookie = function() {
      document.cookie = 'token=';
      console.log('The token was removed from here');
      return true;
    };

    /**
     * Loads all session information into the $rootScope. This function will
     * validate if the user is logged in aswell.
     */
    this.loadStorage = function() {
      const _self = this;

      return $q((resolve, reject) => {
        if (!storageService.getTokenCookie() && !sessionStorage.length) {
          if (window.location.href.indexOf('/building/') > 0) {
            let bid = window.location.href.split('/building/')[1].split('/')[0];
            window.location.href = `/login?redirect-to-building=${bid}`;
            return;
          }
          else {
            return reject();
          }
        }

        const sessionKeys = []; // Creating an iterable array or the session keys

        // Store all session keys information in above array
        for (let i = 0; i < sessionStorage.length; i++) {
          const key = sessionStorage.key(i);
          try {
            if (localStorage.rememberMe) {
              JSON.parse(localStorage[key]);
            }
            else {
              JSON.parse(sessionStorage[key]);
            }
            sessionKeys.push(key);
          }
          catch (err) {

          }
        }

        // Iterate over the sessionKeys and save session key values into $rootScope.
        for (let i=0; i<sessionKeys.length; i++) {
          const sessionKey = sessionKeys[i];
          storageService.get(sessionKey).then((sessionKeyValue) => {
            $rootScope[sessionKey] = sessionKeyValue;
          })
        }
        return storageService.validateToken(storageService.getTokenCookie(), 'login')
          .then(() => {
            // This means that the user is logged in
            $http.defaults.headers.common.Authorization =
              storageService.getTokenCookie() || JSON.parse(_self.getSync('token'));
            return usersService.getUserInformation().then((res) => {
              if (res === 403) {
                resolve(403);
              } else {
                $rootScope.decodedToken = res;
              }

              // FRONTEND: Update the Intercom

              if ($rootScope.decodedToken.user.id) {

                if (res.org.id === 'A59921DE-BA5F-E911-B49E-000D3AF4057E') {
                  window.SHOW_IN_DINARS = true;
                }

                window.USER_ID = $rootScope.decodedToken.user.id;

                // Intercom Setup
                window.Intercom('boot', {
                  app_id: window.intercomAppID,
                  user_id: $rootScope.decodedToken.user.id
                });
                window.Intercom('update', {
                  app_id: window.intercomAppID,
                  user_id: $rootScope.decodedToken.user.id,
                  name: `${res.user.first_name} ${res.user.last_name || ''}`,
                  email: res.user.email,
                  created_at: moment(res.user.account_created).format('X'),
                  phone: res.user.phone,
                  'registration status': res.user.registration_status,
                  company: {
                    id: res.org.id,
                    name: res.org.name,
                    created_at: res.org.create_at
                  }
                });
                //Identify Help Hero user and initiate the Tour for new users
                //HelpHero.identify($rootScope.decodedToken.user.id);

                // Fullstory Setup
                FS.identify(res.user.id, {
                  displayName: `${res.user.first_name} ${res.user.last_name || ''}`,
                  email: res.user.email,
                  orgName: res.org.name,
                  orgId: res.org.id
                });

                // Set the username for Google Analytics
                // FRONTEND: Are we using Google Analytics on this site?
                $analytics.setUsername(res.user.id);

                $rootScope.IS_ADMIN = (res.user.email || '').indexOf('@simptekinc.com') > 0 && (res.user.email || '') !== 'demo@simptekinc.com';
                $rootScope.IS_NFLD = $rootScope.IS_ADMIN || res.org.id === 'D191DF2A-CE16-EA11-828B-00155D0D95F2';
                $rootScope.IS_NBPPR = res.org.id === 'D091DF2A-CE16-EA11-828B-00155D0D95F2';
              }

              return usersService.getPermissions().then((data) => {
                window.PERMISSIONS = $rootScope.PERMISSIONS = data;
                return resolve();
              })
            });
          });
      });
    };

    /**
     * Performs all logout procedures. It clears the $rootScope and
     * the storage data. This is also used to. It also clears Intercom
     */
    this.logout = function() {
      // Delete all the $rootScope Data
      for (const property in $rootScope) {
        if (property.substring(0, 1) !== '$') {
          delete $rootScope[property];
        }
      }

      // Delete the storage data
      sessionStorage.clear();

      // Shutdown Intercom
      window.Intercom('shutdown');

      return $q.resolve();
    };

    // FRONTEND-COMMENT
    this.validateToken = function(token, tokenType, demo) {
      return $q((resolve, reject) => {
        $http.defaults.headers.common.Authorization = token;

        const params = {};
        if (tokenType === 'registration') {
          params.is_registration = true;
        } else if (tokenType === 'reset') {
          params.is_forgot_password = true;
        } else if (tokenType === 'login') {
          params.is_login = true;
        }

        return usersService.validateToken(params, tokenType).then(
          () => {
            let res = null;
            try {
              res = jwt_decode(token);
              if (tokenType === 'login' && !res.is_demo && demo) {
                throw new Error('Not a demo token');
              }
            } catch (e) {
              reject(403);
            }
            if (tokenType === 'login') {
              usersService
                .getUserInformation()
                .then((res2) => {
                  if (window.DEMO_MODE === undefined && res2.user.is_demo) {
                    $rootScope.DEMO_MODE = true;
                    window.DEMO_MODE = true;
                  }

                  $rootScope.decodedToken = res2;
                  $rootScope.decodedToken.user.user_id = res2.user.id;
                  $rootScope.decodedToken.user.email_domain = res2.user.email.split('@')[1];
                  UNIT_SETTINGS.farenheit = $rootScope.decodedToken.preferences.unit_farenheit || false;
                  UNIT_SETTINGS.twelveHour = $rootScope.decodedToken.preferences.unit_twelve_hour || false;
                  UNIT_SETTINGS.gallons = $rootScope.decodedToken.preferences.unit_gallons || false;
                  window.WAS_LOGGED_IN =
                    !!storageService.getTokenCookie() &&
                    window.location.href.indexOf('/login') === -1 &&
                    window.location.href.indexOf('/registration') === -1 &&
                    window.location.href.indexOf('/reset-password') === -1;
                  $rootScope.is_logged_in = window.WAS_LOGGED_IN;
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            } else if (tokenType === 'registration') {
              $rootScope.decodedToken = res;
              $rootScope.decodedToken.user.user_id = res.user.id;
              $rootScope.decodedToken.user.email_domain = res.user.email.split('@')[1];
              resolve();
            }
            return resolve();
          },
          (err) => {
            const reason = err && err.data ? err.data.reason : 'Invalid Token';
            if (tokenType === 'registration') {
              if (reason === 'Expired Token') {
                window.location.href = '/login?param=registration-link-expired';
              } else if (reason === 'User has already registered') {
                if (window.ITOKEN) {
                  window.location.href = `/accept-building-invite?token=${window.ITOKEN}&no-logout=1`;
                  return;
                }
                window.location.href = '/login?param=registration-link-used';
              } else if (reason === 'Invalid Token') {
                window.location.href = '/login?param=expired-session';
              }
            } else if (tokenType === 'reset') {
              window.location.href = '/login?param=reset-password-link-expired';
            } else if (tokenType === 'login') {
              window.location.href = '/login?param=expired-session';
            }
          }
        );
      });
    };

    this.checkLogin = function(success, error) {
      if (window.WAS_LOGGED_IN) {
        $http.defaults.headers.common.Authorization = this.getTokenCookie() || undefined;
        usersService
          .validateToken({ is_login: true }, 'login')
          .then(() => {
            success();
          })
          .catch(() => {
            console.log("!!");
            error();
          });
      } else {
        error();
      }
    };

    // FRONTEND-COMMENT
    this.decodeToken = function(token) {
      return $q((resolve) => resolve(jwt_decode(token)));
    };

    this.expiredToken = function() {
      return this.set('token', null).then(() => {
        this.removeTokenCookie();
        console.log('the token was removed here again');
        window.location.href = '/login?param=expired-session';
      });
    };

    $rootScope.UNIT_SETTINGS = window.UNIT_SETTINGS = {
      farenheit: false,//true,
      twelveHour: false//true
    };

    $rootScope.toFarenheit = (celsius) => (celsius * 1.8 + 32.0);
    $rootScope.toCelsius = (farenheit) => ((farenheit - 32.0) / 1.8);

    setInterval(() => {
      window.WAS_LOGGED_IN =
        !!this.getTokenCookie() &&
        window.location.href.indexOf('/login') === -1 &&
        window.location.href.indexOf('/registration') === -1 &&
        window.location.href.indexOf('/reset-password') === -1;
      $rootScope.is_logged_in = window.WAS_LOGGED_IN;
      if (window.WAS_LOGGED_IN) {
        $http.defaults.headers.common.Authorization = this.getTokenCookie();
        usersService.validateToken({ is_login: true }, 'login').then(
          () => {},
          (status) => {
            if (status === 403) {
              this.expiredToken();
            }
          }
        );
      }
    }, LOGIN_CHECK_INTERVAL_SECONDS * 1000);
  }
]);
