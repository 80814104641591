app.service('usersService', [
  '$http',
  function($http) {
    this.login = function(data) {
      return safeHttp({ method: 'POST', url: '/v2/auth/login', data }).then((res) => res.data, () => {});
    };

    this.logout = function(data) {
      return safeHttp({ method: 'POST', url: '/v2/auth/logout', data }).then(
        (res) => {
          window.WAS_LOGGED_IN = false;
          return res.data;
        },
        () => {}
      );
    };

    this.logoutSafe = function () {
      if (window.location.href.indexOf('no-logout=1') >= 0) {
        return Promise.resolve(null);
      }
      return safeHttp({ method: 'POST', url: '/v2/auth/logout' }).then(
        (res) => {
          window.WAS_LOGGED_IN = false;
          document.cookie = 'token=';
          return null;
        },
        () => {
          window.WAS_LOGGED_IN = false;
          document.cookie = 'token=';
          return null;
        }
      ).catch(() => {
        window.WAS_LOGGED_IN = false;
        document.cookie = 'token=';
        return null;
      });
    };

    this.createAccount = function(data) {
      return safeHttp({ method: 'PUT', url: '/v2/auth/onboard', data }).then((res) => res.data.access_token, () => {});
    };

    this.forgotPassword = function(data) {
      return safeHttp({
        method: 'POST',
        url: '/v2/auth/forgot-password',
        data
      }).catch(() => {});
    };

    this.resetPassword = function(data) {
      return safeHttp({
        method: 'PUT',
        url: '/v2/auth/reset-password',
        data
      }).catch(() => {});
    };

    this.validateToken = function(data) {
      return $http({ method: 'GET', url: '/v2/auth/validate', params: data });
    };

    this.getUserInformation = function() {
      return safeHttp({ method: 'GET', url: '/v2/users' }).then((res) => res.data, (err) => err.status);
    };

    this.getOrgUsers = function() {
      return safeHttp({
        method: 'GET',
        url: `/v2/users/get-org-users-and-invites`,
        params: { }
      }).then(
        (res) => res.data,
        (err) => {
          return {
            errors: err.data
          };
        }
      );
    };

    this.inviteUser = function(data) {
      return safeHttp({
        method: 'POST',
        url: '/v2/users/send-org-collab-invite/',
        data: { ...data }
      }).then(
        (res) => res.data,
        (err) => {
          return {
            errors: err.data || 'Unknown error'
          };
        }
      );
    };

    this.removeOrgUser = function(email) {
      return safeHttp({
        method: 'POST',
        url: `/v2/users/remove-org-user`,
        data: { email }
      }).then(
        (res) => res.data,
        (err) => {
          return {
            errors: err.data
          };
        }
      );
    };

    this.getBGFilters = function() {
      const url = `/v2/users/${window.USER_ID}/groups`;
      return safeHttp({
        method: 'GET',
        url,
        data: { }
      }).then((res) => res.data, () => ({ success: false }));
    };

    this.createBGFilter = function(obj) {
      const url = `/v2/users/${window.USER_ID}/groups`;
      return safeHttp({
        method: 'POST',
        url,
        data: { ...obj }
      }).then((res) => res.data, () => ({ success: false }));
    };

    this.updateBGFilter = function(id, obj) {
      const url = `/v2/users/${window.USER_ID}/groups/${id}`;
      return safeHttp({
        method: 'PUT',
        url,
        data: { ...obj }
      }).then((res) => res.data, () => ({ success: false }));
    };

    this.deleteBGFilter = function(id) {
      const url = `/v2/users/${window.USER_ID}/groups/${id}`;
      return safeHttp({
        method: 'DELETE',
        url,
        data: { }
      }).then((res) => res.data, () => ({ success: false }));
    };

    this.updateOrgUser = function(email, permissions) {
      return safeHttp({
        method: 'POST',
        url: `/v2/users/update-org-user`,
        data: { email, ...permissions }
      }).then(
        (res) => res.data,
        (err) => {
          return {
            errors: err.data
          };
        }
      );
    };

    this.updateUserInformation = function(data) {
      return safeHttp({ method: 'PUT', url: '/v2/users', data }).then(
        (res) => res.data,
        (err) => {
          if (err.status === 422) {
            return {
              errors: err.data
            };
          }
          return null;
        }
      );
    };

    this.updateRtNotificationEmails = function(emailStr, nsStr) {
      return safeHttp({ method: 'PUT', url: '/v2/users/notifications', data: {admin_notify_emails: emailStr, admin_notify_settings: nsStr} }).then(
        (res) => res.data,
        (err) => {
          if (err.status === 422) {
            return {
              errors: err.data
            };
          }
          return null;
        }
      );
    };

    this.getRtNotificationEmails = function() {
      return safeHttp({ method: 'GET', url: '/v2/users/notifications' }).then(
        (res) => res.data,
        (err) => {
          if (err.status === 422) {
            return {
              errors: err.data
            };
          }
          return null;
        }
      );
    };

    this.updatePassword = function(data) {
      return safeHttp({
        method: 'PUT',
        url: '/v2/auth/update-password',
        data
      }).then(
        (res) => res.data,
        (err) => {
          if (err.status === 422) {
            return {
              errors: err.data
            };
          }
          return null;
        }
      );
    };

    this.updateSettings = function(data) {
      return safeHttp({
        method: 'PUT',
        url: '/v2/users/settings',
        data
      }).then(
        (res) => res.data,
        (err) => {
          if (err.status === 422) {
            return {
              errors: err.data
            };
          }
          return null;
        }
      );
    };

    this.getPermissions = function() {
      return safeHttp({ method: 'GET', url: '/v2/users/get-permissions' }).then((res) => res.data, () => {});
    };

    this.getLogo = function() {
      return safeHttp({ method: 'GET', url: '/v2/auth/logo' }).then((res) => res.data, () => {});
    };

    this.getNextInvoice = function() {
      return safeHttp({ method: 'GET', url: '/v2/auth/next-invoice' }, true).then(
        (res) => ({
          ...(res.data || { success: false })
        }),
        () => ({
          success: false
        })
      );
    };
  }
]);
