const app = angular.module('SimpTekMultiDwellingDashboard', [
  'ui.router',
  'ngResource',
  'angulartics',
  'angulartics.google.analytics',
  'ngAutocomplete',
  'ui.tree',
  'gm'
]);

app.config([
  '$stateProvider',
  '$urlRouterProvider',
  '$locationProvider',
  '$compileProvider',
  '$httpProvider',
  function($stateProvider, $urlRouterProvider, $locationProvider, $compileProvider, $httpProvider) {
    $httpProvider.useApplyAsync(true);

    /** Default Route */
    $urlRouterProvider.otherwise('/login');
    /** Enables property URL's */
    $locationProvider.html5Mode(true);
    /** Remove debug statements in HTML */
    $compileProvider.debugInfoEnabled(false);

    /** Routes */
    $stateProvider
      .state('login', {
        url: '/login',
        templateUrl: 'partials/login',
        controller: 'loginController',
        reloadOnSearch: false,
        params: { 
          type: { dynamic: true }
        },
        resolve: {
          decodedToken: [
            'usersService',
            function(usersService) {
              return usersService.logoutSafe();
            }
          ]
        }
      })
      .state('demo', {
        url: '/demo',
        templateUrl: 'partials/demo-login',
        controller: 'demoLoginController',
        resolve: {
          decodedToken: [
            '$location',
            'storageService',
            'usersService',
            function($location, storageService, usersService) {
              return usersService.logoutSafe().then(() => {
                return storageService.validateToken($location.search().token, 'login');
              });
            }
          ]
        }
      })
      .state('resetPassword', {
        url: '/reset-password',
        templateUrl: 'partials/reset-password',
        controller: 'resetPasswordController',
        resolve: {
          decodedToken: [
            '$location',
            'storageService',
            'usersService',
            function($location, storageService, usersService) {
              return usersService.logoutSafe().then(() => {
                return storageService.validateToken($location.search().token, 'reset');
              });
            }
          ]
        }
      })
      .state('registration', {
        url: '/registration',
        templateUrl: 'partials/registration',
        controller: 'registrationController',
        resolve: {
          decodedToken: [
            '$location',
            'storageService',
            '$q',
            'usersService',
            function($location, storageService, $q, usersService) {
              return usersService.logoutSafe().then(() => {
                return $q((resolve, reject) => {
                  let loginToken = storageService.getTokenCookie();
                  if (loginToken) {
                    try {
                      loginToken = jwt_decode(loginToken);
                      if (!loginToken.is_registration) {
                        return reject();
                      }
                    } catch (err) {
                      return reject();
                    }
                  }
                  if ($location.search().itoken) {
                    window.ITOKEN = $location.search().itoken;
                  }
                  return storageService
                    .validateToken($location.search().token, 'registration')
                    .then(() => resolve())
                    .catch(() => reject());
                });
              });
            }
          ]
        }
      })
      .state('acceptBuildingInvite', {
        url: '/accept-building-invite',
        templateUrl: 'partials/accept-building-invite',
        controller: 'acceptBuildingInviteController',
        resolve: {
          decodedToken: [
            '$location',
            'storageService',
            'usersService',
            function($location, storageService, usersService) {
              return usersService.logoutSafe().then(() => {
                return storageService.validateToken($location.search().token, 'login');
              });
            }
          ]
        }
      })
      .state('acceptBuildingInviteTwo', {
        url: '/accept-building-invite-2',
        templateUrl: 'partials/accept-building-invite-two',
        controller: 'acceptBuildingInviteTwoController',
        resolve: {
          decodedToken: [
            '$location',
            'storageService',
            '$q',
            'usersService',
            function($location, storageService, $q, usersService) {
              return usersService.logoutSafe().then(() => {
                return $q((resolve, reject) => {
                  let loginToken = storageService.getTokenCookie();
                  if (loginToken) {
                    try {
                      loginToken = jwt_decode(loginToken);
                      if (!loginToken.is_login) {
                        return reject();
                      }
                    } catch (err) {
                      return reject();
                    }
                  }
                  return storageService
                    .validateToken($location.search().token, 'login')
                    .then(() => resolve())
                    .catch(() => reject());
                });
              });
            }
          ]
        }
      })
      .state('properties', {
        url: '/buildings',
        templateUrl: 'partials/properties',
        //reloadOnSearch: false,
        controllerProvider() {
          return 'propertiesController';
        },
        resolve: {
          property: [
            'storageService',
            '$q',
            function(storageService, $q) {
              return $q((resolve, reject) => {
                storageService.set('settings', null);
                /** Load storage information */
                return storageService
                  .loadStorage()
                  .then((status) => {
                    if (status === 403) {
                      return storageService.expiredToken().then(() => resolve());
                    }
                    return resolve();
                  })
                  .catch(() => {
                    return storageService.expiredToken().then(() => resolve());
                  });
              });
            }
          ]
        }
      })
      .state('property', {
        url: '/building/:propertyId/:type',
        templateUrl: 'partials/property',
        controller: 'propertyController',
        //reloadOnSearch: false,
        resolve: {
          property: [
            'storageService',
            '$q',
            function(storageService, $q) {
              return $q((resolve, reject) => {
                storageService.set('settings', null);
                /** Load storage information */
                return storageService
                  .loadStorage()
                  .then((status) => {
                    if (status === 403) {
                      return storageService.expiredToken().then(() => resolve());
                    }
                    return resolve();
                  })
                  .catch(() => {
                    return storageService.expiredToken().then(() => resolve());
                  });
              });
            }
          ]
        },
        params: {
          type: {
            dynamic: true
          }
        }
      })
      .state('property-offers', {
        url: '/marketplace/building/:propertyId/offer/:offerId',
        templateUrl: 'partials/property-offers-main',
        controller: 'propertyOffersMainController',
        //reloadOnSearch: false,
        resolve: {
          property: [
            'storageService',
            '$q',
            function(storageService, $q) {
              return $q((resolve, reject) => {
                storageService.set('settings', null);
                /** Load storage information */
                return storageService
                  .loadStorage()
                  .then((status) => {
                    if (status === 403) {
                      return storageService.expiredToken().then(() => resolve());
                    }
                    return resolve();
                  })
                  .catch(() => {
                    return storageService.expiredToken().then(() => resolve());
                  });
              });
            }
          ]
        },
        params: {
        }
      })
      .state('settings', {
        url: '/settings/:type',
        templateUrl: 'partials/settings',
        controller: 'settingsController',
        resolve: {
          management: [
            'storageService',
            '$q',
            function(storageService, $q) {
              return $q((resolve, reject) =>
                storageService
                  .loadStorage()
                  .then((status) => {
                    if (status === 403) {
                      return storageService.expiredToken().then(() => resolve());
                    }
                    return resolve();
                  })
                  .catch(() => {
                    return storageService.expiredToken().then(() => resolve());
                  })
              );
            }
          ]
        },
        params: {
          type: {
            dynamic: true
          }
        }
      });
  }
]);

/** ********************** */
/** ******** RUN ********* */
/** ********************** */
app.run([
  '$rootScope',
  '$timeout',
  '$state',
  '$location',
  'userService',
  '$transitions',
  'storageService',
  function($rootScope, $timeout, $state, $location, userService, $transitions, storageService) {
    /** *********************** */
    /** ******** INIT ********* */
    /** *********************** */
    const mobileAndTabletCheck = () => {
      let check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    };

    /** ************************************** */
    /** ******** ROOTSCOPE VARIABLES ********* */
    /** ************************************** */

    $rootScope.$deviceDetection = () => {
      if (mobileAndTabletCheck()) {
        $rootScope.$openModal('device detected');
      }
    };

    /** *************************** */
    /** ******** WATCHERS ********* */
    /** *************************** */

    $transitions.onBefore({}, (transition) => {
      if ($rootScope.skipNextStateChange) {
        $rootScope.skipNextStateChange = false;
        return;
      }
      //$rootScope.skipUpdateFromState = false;
      $rootScope.$exitOverlay();
      moment.tz.setDefault();
      const propertyId =
        $rootScope.property && $rootScope.property.profile ? $rootScope.property.profile.id : undefined;
      if (
        transition.from().name === 'property' &&
        transition.to().name === 'property' &&
        transition.params().propertyId === propertyId
      ) {
        /*if ($rootScope.tabSelected === (transition.params().type === 'real-time' ? 'Real-Time Monitoring' : transition.params().type === 'profile' ? 'Profile' : 'Monthly Monitoring')) {
          $rootScope.skipUpdateFromState = true;
          return;
        }*/
        $('#body').animate(
          {
            scrollTop: 0
          },
          250
        );
        $rootScope.$pageLoader(true);
        return new Promise((resolve) =>
          setTimeout(() => {
            $rootScope.basePropertyPageLoad = false;
            setTimeout(() => {
              if ($rootScope.property) {
                $rootScope.property.selected_tab =
                  transition.params().type === 'real-time' ? 'Real-Time Monitoring' : transition.params().type === 'profile' ? 'Profile' : 'Monthly Monitoring';
              }
              $rootScope.tabSelected =
                transition.params().type === 'real-time' ? 'Real-Time Monitoring' : transition.params().type === 'profile' ? 'Profile' : 'Monthly Monitoring';
              storageService.set('settings', $rootScope.settings);
              resolve();
            }, 500);
          }, 250)
        );
      }
      if (transition.from().name === 'settings' && transition.to().name === 'settings') {
        /*if ($rootScope.tabSelected === (transitionType.charAt(0).toUpperCase() + transitionType.slice(1))) {
          $rootScope.skipUpdateFromState = true;
          return;
        }*/
        $('#body').animate(
          {
            scrollTop: 0
          },
          250
        );
        $rootScope.$pageLoader(true);
        return new Promise((resolve) =>
          setTimeout(() => {
            $rootScope.baseSettingsPageLoad = false;
            setTimeout(() => {
              const transitionType = transition.params().type;
              const capType = transitionType.charAt(0).toUpperCase() + transitionType.slice(1);
              $rootScope.tabSelected = capType === 'Building-groups' ? 'Building Groups' : capType;
              $rootScope.settings = {selected_tab: capType === 'Building-groups' ? 'Building Groups' : capType};
              resolve();
            }, 500);
          }, 250)
        );
      }
      else if (transition.to().name === 'settings') {
        $rootScope.baseSettingsPageLoad = false;
        const transitionType = transition.params().type;
        const capType = transitionType.charAt(0).toUpperCase() + transitionType.slice(1);
        $rootScope.tabSelected = capType === 'Building-groups' ? 'Building Groups' : capType;
        $rootScope.settings = {selected_tab: capType === 'Building-groups' ? 'Building Groups' : capType};
        return new Promise((resolve) => setTimeout(resolve, 500));
      }
      if (transition.to().name === 'property' || transition.to().name === 'settings') {
        $rootScope.forceTabs = false;
        $rootScope.readyTabs = false;
        $rootScope.$globalLoader(true);
        return new Promise((resolve) => setTimeout(resolve, 500));
      }
      if (transition.from().name === '') {
        $rootScope.$globalLoader(true, 500);
      } else {
        $rootScope.$globalLoader(true);
        return new Promise((resolve) => setTimeout(resolve, 500));
      }
      return true;
    });

    $transitions.onSuccess({}, (transition) => {
      $rootScope.currentState = '';
      $timeout(() => {
        $rootScope.forceTabs = false;
        $rootScope.currentState = transition.to().name;
        $timeout(() => {
          if ($rootScope.$updateTabsObject) {
            $rootScope.$updateTabsObject();
          }
        }, 250);
      }, 250);

      /** Checks if the Auth background should be added. */
      if (
        transition.to().name === 'login' ||
        transition.to().name === 'resetPassword' ||
        transition.to().name === 'registration' ||
        transition.to().name === 'acceptBuildingInvite' ||
        transition.to().name === 'acceptBuildingInviteTwo'
      ) {
        $rootScope.showHeaderFooter = false;
        setTimeout(() => {
          const $window = $(window);
          const $bg = $('.auth-bg');
          const aspectRatio = $bg.width() / $bg.height();
          $window
            .resize(() => {
              /** This is located in the Operations Controller */
              $rootScope.$resizeBg($bg, aspectRatio);
            })
            .trigger('resize');
        }, 500);
      } else {
        $rootScope.showHeaderFooter = true;
      }

      /** Handles the org URL redirect */
      $rootScope.$getOrgLogo(!$rootScope.showHeaderFooter, transition.to().name);
    });

    $transitions.onError({}, (transition) => {
      $rootScope.skipNextStateChange = false;
      if (transition.error().type !== 6) {
        return;
      }

      if (transition.error().detail && transition.error().detail.status === 403) {
        storageService.expiredToken();
      }

      window.location.href = '/login';
    });

    $transitions.onFinish({}, (transition) => {
      $rootScope.skipNextStateChange = false;
      $timeout(() => {
        $rootScope.activeSidebar = '';
        $rootScope.propertySearch = '';
        if ($rootScope.skipUpdateFromState) {
          $rootScope.skipUpdateFromState = false;
          return;
        }
        $rootScope.fromState = transition.from().name;
      });
    });
  }
]);
