app.directive('deviceDetectedDirective', [
  function() {
    return {
      restrict: 'E', // Bind to element
      scope: false, // The $scope is shared with its parent.
      templateUrl: 'partials/modals/device-detected',
      controller: 'deviceDetectedController'
    };
  }
]);
