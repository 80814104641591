app.service('globalService', [
  '$rootScope',
  '$q',
  '$timeout',
  'userService',
  '$http',
  '$location',
  function($rootScope, $q, $timeout, userService, $http, $location) {
    this.init = () => {
      /** **************************** */
      /** ******** VARIABLES ********* */
      /** **************************** */

      /** The Params Object */
      const paramMessages = [
        /** FORGOT PASSWORD */
        {
          param: 'forgot-password-sent',
          lg: 'Check your inbox!',
          md: {
            one: 'An email was sent to ',
            two: ' with instructions on how to reset your password.'
          },
          sm:
            'It may take a few minutes to arrive. If you do not receive the email within 15 minutes, please contact support@simptekinc.com',
          button: {
            state: 'login',
            class: 'back-button',
            text: 'Back To Login'
          }
        },
        {
          param: 'forgot-password-not-sent',
          lg: 'An issue has occurred.',
          md: {
            one: 'We were unable to send an email to ',
            two: '.'
          },
          sm: 'Please try again or contact support@simptekinc.com',
          button: {
            state: 'login',
            class: 'back-button',
            text: 'Back To Login'
          }
        },

        /** RESET PASSWORD */
        {
          param: 'reset-password-success',
          lg: 'You have successfully reset your password.',
          md: 'Please use your new password to log in.',
          sm: '',
          button: {
            state: 'login',
            class: 'back-button',
            text: 'Back To Login'
          }
        },
        {
          param: 'reset-password-failed',
          lg: 'An error has occurred.',
          md: 'Please try again or contact support@simptekinc.com to reset your password',
          sm: '',
          button: {
            state: 'login',
            class: 'back-button',
            text: 'Back To Login'
          }
        },
        {
          param: 'reset-password-link-expired',
          lg: 'Your reset password link has expired.',
          md: 'If you would like to reset your password again, please submit another request.',
          sm: '',
          button: {
            state: 'login',
            class: 'back-button',
            text: 'Back To Login'
          }
        },

        /** REGISTRATION */
        {
          param: 'registration-link-expired',
          lg: 'Your registration link has expired.',
          md: 'Please contact support@simptekinc.com to register.',
          sm: '',
          button: {
            state: 'login',
            class: 'back-button',
            text: 'Back To Login'
          }
        },
        {
          param: 'registration-link-used',
          lg: 'Your account has already been created.',
          md: 'If you have forgotten your password, please click on the "forgot my password" link from the Login page.',
          sm: 'If you cannot access your account, please contact support@simptekinc.com',
          button: {
            state: 'login',
            class: 'back-button',
            text: 'Back To Login'
          }
        },
        {
          param: 'registration-creation-error',
          lg: 'An error has occurred.',
          md: 'To complete your account setup, please contact support@simptekinc.com',
          sm: '',
          button: {
            state: 'login',
            class: 'back-button',
            text: 'Back To Login'
          }
        },

        /** IN USE GENERAL ERROR */
        {
          param: 'expired-session',
          lg: 'Your session has expired.',
          md: 'Please login again.',
          sm: '',
          button: {
            state: 'login',
            class: 'back-button',
            text: 'Back To Login'
          }
        }
      ];

      /** **************************** */
      /** ******** FUNCTIONS ********* */
      /** **************************** */

      window.safeHttp = (args, noRedirect) =>
        $q((resolve, reject) => {
          $http(args).then(
            (res) => {
              resolve(res);
            },
            (err) => {
              if (err.status === 403 && window.WAS_LOGGED_IN && !noRedirect) {
                window.location.href = '/login?param=expired-session';
              }
              reject(err);
            }
          );
        });

      /** ***************************** */
      /** ******** SOFT STATE ********* */
      /** ***************************** */

      /**
       * This function filteres through the different params options and assignes the right object to the global message.
       * @param {string} param - The value of the param.
       * @param {string} email - The value of the email param, if it exists.
       */
      $rootScope.$findGlobalMessage = (param, emailParam) => {
        for (let i = 0; i < paramMessages.length; i++) {
          const message = {...paramMessages[i]};
          if (param === message.param) {
            const email = emailParam || '';

            if (message.md.one && message.md.two) {
              message.md = message.md.one + email + message.md.two;
            }

            $rootScope.globalMessage = {
              lg: message.lg,
              md: message.md,
              sm: message.sm,
              button: {
                state: message.button.state,
                text: message.button.text,
                class: message.button.class
              }
            };

            $location.search({});
            $rootScope.$stateChange('globalMessage');

            break;
          }
        }

        if (!$rootScope.globalMessage) {
          $location.search({});
          $rootScope.$stateChange('login');
        }
      };

      /** *************************** */
      /** ******** REDIRECT ********* */
      /** *************************** */
    };
  }
]);
