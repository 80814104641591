app.controller('acceptBuildingInviteController', [
  '$scope',
  '$state',
  '$rootScope',
  'userService',
  'storageService',
  '$location',
  'initService',
  '$http',
  function($scope, $state, $rootScope, userService, storageService, $location, initService, $http) {
    /** **************************** */
    /** ******** VARIABLES ********* */
    /** **************************** */

    /** ********************************* */
    /** ******** INIT FUNCTIONS ********* */
    /** ********************************* */

    document.cookie = `token=${$location.search().token}`;
    $http.defaults.headers.common.Authorization = $location.search().token;

    storageService.validateToken($location.search().token, 'login', false).then(() =>
      userService.getUserInformation().then(() => {
        window.location.href = `/accept-building-invite-2?token=${$location.search().token}&no-logout=1`;
      })
    );

    /** *************************** */
    /** ******** Watchers ********* */
    /** *************************** */

    /** ********************************** */
    /** ******** SCOPE VARIABLES ********* */
    /** ********************************** */

    /** ********************************** */
    /** ******** SCOPE FUNCTIONS ********* */
    /** ********************************** */

    /** **************************** */
    /** ******** FUNCTIONS ********* */
    /** **************************** */
  }
]);