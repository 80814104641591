app.controller('loginController', [
  '$scope',
  '$state',
  '$rootScope',
  '$timeout',
  'userService',
  'storageService',
  '$location',
  'initService',
  function($scope, $state, $rootScope, $timeout, userService, storageService, $location, initService) {
    /** **************************** */
    /** ******** VARIABLES ********* */
    /** **************************** */

    /** ********************************* */
    /** ******** INIT FUNCTIONS ********* */
    /** ********************************* */
    storageService.logout();

    /** Check if the url has a param */
    if ($location.search().param !== undefined) {
      $rootScope.$findGlobalMessage($location.search().param, $location.search().email);
    } else {
      $rootScope.$stateChange('login');
    }

    $rootScope.$deviceDetection();
    moment.tz.setDefault();
    initService.general();
    $rootScope.$globalLoader(false);

    /** *************************** */
    /** ******** Watchers ********* */
    /** *************************** */

    /** ********************************** */
    /** ******** SCOPE VARIABLES ********* */
    /** ********************************** */

    /** ********************************** */
    /** ******** SCOPE FUNCTIONS ********* */
    /** ********************************** */
    /**
     * The login function.
     * @param {string} email - User's email.
     * @param {string} password - User's password.
     */
    $scope.login = function(email, password) {
      if (!$scope.loaderLogin) {
        $scope.loaderLogin = true;
        $rootScope.showError = false;
        userService
          .login(email, password)
          .then((res) =>
            storageService.validateToken(res.access_token, 'login').then(() =>
              userService.getUserInformation().then(() => {
                let building = '';
                if (window.location.href.indexOf("?redirect-to-building=") >= 0) {
                  building = `?redirect-to-building=${window.location.href.split("?redirect-to-building=")[1].split("#")[0].split("&")[0]}`;
                }
                if ($rootScope.decodedToken && !window.NO_REDIRECT) {
                  window.location.href = `${$rootScope.decodedToken.org.dashboard_url}/buildings${building}`;
                } else {
                  window.location.href = `/buildings${building}`;
                }
              })
            )
          )
          .catch(() => {
            $timeout(() => {
              $scope.loaderLogin = false;
              $rootScope.showError = true;
            }, 500);
          });
      }
    };

    /**
     * The function to send out the reset password email.
     * @param {string} email - User's email.
     */
    $scope.forgotPassword = function(email) {
      $scope.loaderForgot = true;
      return userService
        .forgotPassword(email)
        .then(() => {
          $scope.loaderForgot = false;
          $rootScope.$findGlobalMessage('forgot-password-sent', email);
        })
        .catch(() => {
          $scope.loaderForgot = false;
          $rootScope.$findGlobalMessage('forgot-password-not-sent', email);
        });
    };

    /**
     * Once the global message is hit, this will kick.
     * This is needed to allow for the soft state change if the desired state is the login page
     */
    $scope.goToStateChange = function() {
      $scope.loaderLogin = false;
      $scope.loaderForgot = false;
      if ($rootScope.globalMessage.button.state === 'login') {
        $rootScope.$stateChange('login');
      } else {
        $state.go($rootScope.globalMessage.button.state);
      }
    };

    /** **************************** */
    /** ******** FUNCTIONS ********* */
    /** **************************** */
  }
]);
