app.service('loaderService', [
  '$rootScope',
  '$timeout',
  function($rootScope, $timeout) {
    this.init = () => {
      /** **************************** */
      /** ******** VARIABLES ********* */
      /** **************************** */
      let callEndGlobalLoader;
      let timerEndGlobalLoader;
      let callEndPageLoader;
      let timerEndPageLoader;

      /** **************************** */
      /** ******** FUNCTIONS ********* */
      /** **************************** */
      function closeGlobalLoader() {
        timerEndGlobalLoader = false;
        callEndGlobalLoader = false;
        $timeout(() => {
          // $scope.$$postDigest(() => {
          $timeout(() => {
            $rootScope.hideGlobalLoader = true; /** Fade out overlay and loader */
          }, 500);
          $timeout(() => {
            $rootScope.indexHidden = true; /** Decrease z-index */
          }, 1000);
          // });
        });
      }

      function closePageLoader() {
        timerEndPageLoader = false;
        callEndPageLoader = false;
        $rootScope.tabLoader = false;
        $timeout(() => {
          // $scope.$$postDigest(() => {
          $timeout(() => {
            $rootScope.fadeInPageLoader = false; /** Fade out overlay and loader */
          }, 500);
          $timeout(() => {
            $rootScope.showPageLoader = false; /** Decrease z-index */
          }, 1000);
          // });
        });
      }

      /** ************************* */
      /** ******** LOADER ********* */
      /** ************************* */

      $rootScope.$globalLoader = (boolean, time) => {
        const timeInterval = !time ? 1500 : time;

        if (boolean === true) {
          $timeout(() => {
            $rootScope.indexHidden = false; /** Decreases z-index */
            $timeout(() => {
              $rootScope.hideGlobalLoader = false; /** Fade out overlay and loader */
            }, 500);
          });

          setTimeout(() => {
            if (callEndGlobalLoader) {
              closeGlobalLoader();
            } else {
              timerEndGlobalLoader = true;
            }
          }, timeInterval);
        } else if ($rootScope.indexHidden || $rootScope.indexHidden === undefined) {
          $timeout(() => {
            $rootScope.indexHidden = false; /** Increases z-index */
            $timeout(() => {
              $rootScope.hideGlobalLoader = false; /** Fade in overlay and loader */
              $timeout(() => {
                closeGlobalLoader();
              }, 1500);
            }, 500);
          });
        } else if (timerEndGlobalLoader) {
          closeGlobalLoader();
        } else {
          callEndGlobalLoader = true;
        }
      };

      $rootScope.$pageLoader = (boolean) => {
        const timeInterval = 500;

        if (boolean === true) {
          $rootScope.tabLoader = true;
          $timeout(() => {
            $rootScope.showPageLoader = true; /** Increases z-index */
            $timeout(() => {
              $rootScope.fadeInPageLoader = true; /** Fade in overlay and loader */
            }, 500);
          });
          setTimeout(() => {
            if (callEndPageLoader) {
              closePageLoader();
            } else {
              timerEndPageLoader = true;
            }
          }, timeInterval);
        } else if ($rootScope.showPageLoader || $rootScope.showPageLoader === undefined) {
          $timeout(() => {
            $rootScope.showPageLoader = true; /** Decreases z-index */
            $timeout(() => {
              $rootScope.fadeInPageLoader = true; /** Fade out overlay and loader */
              $timeout(() => {
                closePageLoader();
              }, 500);
            }, 500);
          });
        } else if (timerEndPageLoader) {
          closePageLoader();
        } else {
          callEndPageLoader = true;
        }
      };

      $rootScope.$removeSectionLoader = () => {
        $rootScope.tabLoader = false;
        if (!$rootScope.indexHidden) {
          $rootScope.$globalLoader(false);
        } else if ($rootScope.showPageLoader) {
          $rootScope.$pageLoader(false);
        }
      };
    };
  }
]);
