app.controller('acceptBuildingInviteTwoController', [
  '$scope',
  '$state',
  '$rootScope',
  'userService',
  'storageService',
  '$location',
  'initService',
  '$http',
  'propertyService',
  function($scope, $state, $rootScope, userService, storageService, $location, initService, $http, propertyService) {
    /** **************************** */
    /** ******** VARIABLES ********* */
    /** **************************** */

    /** ********************************** */
    /** ******** SCOPE VARIABLES ********* */
    /** ********************************** */

    /** Init form value based on decoded token */
    const token = $location.search().token;

    /** **************************** */
    /** ******** FUNCTIONS ********* */
    /** **************************** */

    /** ********************************* */
    /** ******** INIT FUNCTIONS ********* */
    /** ********************************* */
    storageService
      .decodeToken(token)
      .then((res) => {
        $rootScope.decodedToken = res;
        propertyService.userAcceptPropertyInvite(res.invite_id)
          .then((data) => {
            if (data.success === true) {
              const property_id = data.property_id;
              if (property_id) {
                window.location.href = `/building/${property_id}/profile`;
              }
              else {
                window.location.href = `/buildings`;
              }
            }
          });
      })

    /** *************************** */
    /** ******** Watchers ********* */
    /** *************************** */

    /** ********************************** */
    /** ******** SCOPE FUNCTIONS ********* */
    /** ********************************** */

  }
]);