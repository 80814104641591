app.service('intercomService', [
  '$rootScope',
  function($rootScope) {
    this.init = () => {
      $rootScope.$addRealTimeClick = () => {
        const buildingName =
          $rootScope.property && $rootScope.property.profile ? $rootScope.property.profile.name : undefined;
        window.Intercom(
          'showNewMessage',
          `Hello, I am interested in speaking about installing a real-time energy monitoring system in ${
            buildingName ? `Building ${buildingName}` : 'a building'
          }.`
        );
      };

      $rootScope.$addPropertyCTAClick = () => {
        window.Intercom('showNewMessage', 'Hi, I would like to add new buildings to my organization account.');
      };

      $rootScope.$addPropertyImageClick = (buildingName) => {
        window.Intercom(
          'showNewMessage',
          `Hi, I would like to upload a new image for ${buildingName ? `Building ${buildingName}` : 'this building'}.`
        );
      };

      $rootScope.$failedPendingItem = (data) => {
        if (data.is_realtime) {
          if (data.connected_sensors === 0) {
            window.Intercom(
              'showNewMessage',
              `Hello, I am wondering why all of the realtime devices are disconnected in ${data.property_name}?`
            );
          } else {
            window.Intercom(
              'showNewMessage',
              `Hello, I am wondering why some of the realtime devices are disconnected in ${data.property_name}?`
            );
          }
        } else if (data.status === 'failed') {
          window.Intercom(
            'showNewMessage',
            `Hello, I am wondering why ${data.meter_id} failed to add to ${data.property_name}?`
          );
        } else {
          window.Intercom(
            'showNewMessage',
            `Hello, I am wondering why ${data.meter_id} in ${data.property_name} is disconnected?`
          );
        }
      };

      $rootScope.$addDeviceImageClick = (buildingName, deviceName) => {
        window.Intercom(
          'showNewMessage',
          `Hello, I would like to upload a new image for ${deviceName || 'this device'} in ${
            buildingName ? `${buildingName}` : 'this building'
          }.`
        );
      };

      $rootScope.$uploadHistoricalClick = (buildingName) => {
        window.Intercom(
          'showNewMessage',
          `Hi, I would like to upload historical data for ${
            buildingName ? `Building ${buildingName}` : 'this building'
          }.`
        );
      };
    };
  }
]);
