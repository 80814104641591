app.service('navigationService', [
  '$rootScope',
  '$q',
  '$timeout',
  'userService',
  '$http',
  '$location',
  '$state',
  'storageService',
  '$stateParams',
  '$window',
  function($rootScope, $q, $timeout, userService, $http, $location, $state, storageService, $stateParams, $window) {
    this.init = () => {
      /** **************************** */
      /** ******** FUNCTIONS ********* */
      /** **************************** */
      function dashboardUrlRedirect() {
        const dashboardHost = `${$rootScope.decodedToken.org.dashboard_url}`
          .replace(/http[s]*:\/\//g, '')
          .replace(/\//g, '');

        if ($rootScope.decodedToken && $location.host() !== dashboardHost) {
          if (!window.NO_REDIRECT) {
            const newURL = $rootScope.decodedToken.org.dashboard_url + $location.path();

            if (`${newURL}`.trim('/') !== `${$window.location.href}`.trim('/')) {
              $window.location.href = newURL;
            }
          }
        }
      }

      /** ***************************** */
      /** ******** SOFT STATE ********* */
      /** ***************************** */

      /**
       * This allows us to make a "Soft State Change", meaning a portion of the page will fade out, mean while another portion will fade in.
       * This gives the illusion of a state change all while remaining on the same url.
       * @param {string} state - Name of the soft state.
       */
      $rootScope.$stateChange = (state) => {
        $rootScope.showError = false;
        $rootScope.state = null;
        $timeout(() => {
          $rootScope.upZIndex = state;
          $rootScope.state = state;
        }, 500);
      };

      /** *************************** */
      /** ******** REDIRECT ********* */
      /** *************************** */

      $rootScope.$getOrgLogo = (isAuth, toState) => {
        if (isAuth) {
          userService.getLogo().then((res) => {
            if (res.logo_url) {
              $timeout(() => {
                $rootScope.orgLogo = {
                  'background-image': `url(${res.logo_url})`
                };
                $rootScope.orgLogoURL = res.logo_url;
              });
            }
          });
        } else {
          dashboardUrlRedirect(toState);
          $rootScope.orgLogo = {
            'background-image': `url(${$rootScope.decodedToken.org.logo_url})`
          };
          $rootScope.orgLogoURL = $rootScope.decodedToken.org.logo_url;
        }
      };

      $rootScope.$goToProperties = () => {
        if ($state.current.name === 'properties') {
          window.location.href = '/buildings';
        }

        $rootScope.$goToStateChange('state', 'properties');
      };

      $rootScope.$goToProperty = (id) => {
        if ($rootScope.propertySearchArray) {
          for (let i = 0; i < $rootScope.propertySearchArray.length; i++) {
            const property = $rootScope.propertySearchArray[i];
            if (property.selected && property.id === id) {
              return;
            }
            property.selected = property.id === id;
          }
        }

        $rootScope.property = null;
        $rootScope.$goToStateChange('state', 'property', {
          propertyId: id,
          type: 'profile'
        });
      };

      $rootScope.$goToPropertyOffers = (id, offerId) => {
        if ($rootScope.propertySearchArray) {
          for (let i = 0; i < $rootScope.propertySearchArray.length; i++) {
            const property = $rootScope.propertySearchArray[i];
            if (property.selected && property.id === id) {
              return;
            }
            property.selected = property.id === id;
          }
        }

        $rootScope.$propertyOffers(false);

        $rootScope.property = null;
        $rootScope.$goToStateChange('state', 'property-offers', {
          propertyId: id,
          offerId: offerId
        });
      };

      $rootScope.$changeTabs = (type) => {
        if ($rootScope.tabSelected !== type) {
          $rootScope.tabSelected = type;
          $rootScope.$goToStateChange('directive', 'property', type, 'selected_tab');
        }
      };

      $rootScope.$logout = () => {
        userService.logout().then(() => {
          $rootScope.$goToStateChange('state', 'login');
        });
      };
    };

    $rootScope.$goToStateChange = (type, a, b, c) => {
      $rootScope.$closeBubble();
      if ($rootScope.currentState === 'property' || $rootScope.currentState === 'settings') {
        $rootScope.forceTabs = true;
      }
      else {
        $rootScope.forceTabs = false;
      }
      if (type === 'directive') {
        if (a === 'property') {
          const propertyId = $stateParams.propertyId ? $stateParams.propertyId : $rootScope.property.profile.id;
          $rootScope.forceTabs = true;
          $state.go(
            $rootScope.currentState === a ? '.' : a,
            {
              propertyId,
              type: b === 'Real-Time Monitoring' ? 'real-time' : b === 'Profile' ? 'profile' : 'monthly'
            },
            { reloadOnSearch: false }
          );
        } else if (a === 'settings') {
          $rootScope.forceTabs = true;
          const typeB = `${b}`.toLowerCase();
          $state.go(
            $rootScope.currentState === a ? '.' : a,
            {
              type: typeB === 'building groups' ? 'building-groups' : typeB
            },
            { reloadOnSearch: false }
          );
        } else {
          $('#body').animate(
            {
              scrollTop: 0
            },
            500
          );
          $rootScope.$pageLoader(true);
          $timeout(() => {
            $rootScope[a][c] = b;
            storageService.set('settings', $rootScope.settings);
          }, 1000);
        }
      } else {
        $rootScope.$globalLoader(true);
        $timeout(() => {
          if (type === 'location') {
            $location.url(a);
          } else if (type === 'state') {
            $state.go(a, b);
          }
        }, 1000);
      }
    };

    $rootScope.$readyTour = (flag) => {
      /*$timeout(() => {
        $rootScope.skipNextStateChange = flag;
        window.history.pushState('', '', window.location.href.split('?')[0] + (flag ? '?ready-tour' : ''));
        $timeout(() => {
          $rootScope.skipNextStateChange = false;
        }, 10);
      }, flag ? 500 : 0);*/
    };
  }
]);
