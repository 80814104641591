/** This filter can not handle decimals over 4 and over */

// https://stackoverflow.com/questions/18604374/angularjs-orderby-with-empty-field
app.filter('zeroAtEnd', [
  function() {
    return function (array, key) {
      if(!angular.isArray(array)) return;
      var present = array.filter(function (item) {
          return item[key];
      });
      var empty = array.filter(function (item) {
          return !item[key]
      });
      return present.concat(empty);
    };
  }
]);

app.filter('numberFormater', [
  function() {
    return function(value, unit, decimal, leftPosition, leftUnit) {
      let currentvalue = value;
      let formatedValue;
      if (currentvalue === undefined) {
        return '';
      }
      if (isNaN(currentvalue)) {
        return 'N/A';
      }
      if (unit === '$' && window.SHOW_IN_DINARS) { 
        unit = ' BD'; 
        //currentvalue /= 3.53; 
        leftPosition = false; 
      } 
      if (`${unit}`.substring(0, 2) === ' L' && window.UNIT_SETTINGS.gallons) {
        unit = ` gal.${`${unit}`.substring(2)}`;
        currentvalue /= 3.785 / 1000;
      }
      else if (`${unit}`.substring(0, 2) === ' L') {
        unit = ` m³${`${unit}`.substring(2)}`;
      }
      if (decimal >= 0 && decimal <= 6) {
        const decimalMultiplyer = 10 ** decimal;
        currentvalue = Math.floor(currentvalue * decimalMultiplyer) / decimalMultiplyer;
        currentvalue = parseFloat(Math.round(currentvalue * decimalMultiplyer) / decimalMultiplyer).toFixed(decimal);
        formatedValue = String(Math.floor(currentvalue)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (decimal > 0 ? '.' + (String((currentvalue - Math.floor(currentvalue)).toFixed(decimal)).split('.')[1] || '') : '');
        if (leftUnit) {
          formatedValue = leftUnit + formatedValue;
        }
        if (leftPosition) {
          formatedValue = unit + formatedValue;
        } else {
          formatedValue += unit;
        }
        /** This handles cases where the unit containes html entities */
        formatedValue = he.decode(formatedValue);
      } else {
        formatedValue = 'Error: To many decimals';
      }

      return formatedValue;
    };
  }
]);
