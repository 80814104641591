app.service('componentsService', [
  '$rootScope',
  '$timeout',
  function($rootScope, $timeout) {
    this.init = () => {
      /** **************************** */
      /** ******** VARIABLES ********* */
      /** **************************** */
      const BUBBLE_BUTTON_LEAVE_TIME = 0.25;
      const BUBBLE_LEAVE_TIME = 0.25;

      $rootScope.$catchExitOverlay = null;
      /** The password requirement object. */
      $rootScope.requirements = {};
      /** The new password success, error and neutral state. */
      $rootScope.newInputCheck = '';
      /** The confirmed password success, error and neutral state. */
      $rootScope.confirmInputCheck = '';
      $rootScope.bubbleOpen = false;

      /** ************************ */
      /** ******** INPUT ********* */
      /** ************************ */

      $rootScope.waitOrMouseLeave = (callback, time, element) => {
        let cancelled = false;
        const handler = () => {
          cancelled = true;
          element.removeEventListener('mouseleave', handler);
        };
        element.addEventListener('mouseleave', handler);
        setTimeout(() => {
          if (!cancelled) {
            element.removeEventListener('mouseleave', handler);
            callback();
          }
        }, time);
      };

      $rootScope.$changePasswordType = (id) => {
        const passwordElement = document.getElementById(id);
        passwordElement.type =
          passwordElement.type === 'password' ? (passwordElement.type = 'text') : (passwordElement.type = 'password');
      };

      /**
       * Clears the appropriate search input
       * @param {{}} element - .cancel-icon
       */
      $rootScope.$clearInput = (element, currentElement) => {
        /** This variable contains the appropriate .search-input element. */
        const input = element ? element.currentTarget.searchInput : currentElement;

        $rootScope.$assignRootScopeVar(input.children[0], false);
        /** Clears the input's value */
        input.children[0].value = '';
        input.classList.remove('cancel-option');
      };

      /**
       * This checks the new password field and makes the successed requirements true.
       * @param {string} newPassword - New Password
       * @param {string} confirmedPassword - Confirmed Password
       */
      $rootScope.$checkPasswordRequirements = (newPasswordParam, confirmedPasswordParam) => {
        /** Error Checking */
        const newPassword = newPasswordParam === undefined ? '' : newPasswordParam;

        $rootScope.requirements = $rootScope.requirements || {};

        /** 8 characters Check */
        $rootScope.requirements.characters = newPassword.length >= 8;

        /** 1 UpperCase Character Check */
        $rootScope.requirements.uppercase = /^(?=.*[A-Z]).+$/.test(newPassword);

        /** 1 LowerCase Character Check */
        $rootScope.requirements.lowercase = /^(?=.*[a-z]).+$/.test(newPassword);

        /** 1 Special Character Check */
        $rootScope.requirements.special = /[-#?!@$%^&*-]/.test(newPassword);

        /** 1 Number Character Check */
        $rootScope.requirements.number = newPassword.match(/\d+/g);

        if (
          $rootScope.requirements.characters &&
          $rootScope.requirements.uppercase &&
          $rootScope.requirements.lowercase &&
          $rootScope.requirements.special &&
          $rootScope.requirements.number
        ) {
          $rootScope.requirements.noCheck = false;
          $rootScope.newInputCheck = 'success';
        } else {
          $rootScope.newInputCheck = '';
          $rootScope.requirements.noCheck = true;
        }

        if ($rootScope.newInputCheck === 'success') {
          $rootScope.onCheckNewPassword($rootScope.newInputCheck === 'success');
        }

        $rootScope.$checkConfirmPassword(newPassword, confirmedPasswordParam);
      };

      /**
       * Applies the error status to the new password input on blur if the conditions for the password requirement fails.
       * This allows the error to be potentioally set only on blur
       */
      $rootScope.$checkNewPassword = () => {
        $rootScope.requirements = $rootScope.requirements || {};
        
        if ($rootScope.requirements.noCheck) {
          $rootScope.newInputCheck = 'error';
        }

        if ($rootScope.onCheckNewPassword) {
          $rootScope.onCheckNewPassword($rootScope.newInputCheck === 'success');
        }
      };

      /**
       * Applies the success status if the conditions are met and a neutral if they are not on keyup
       * @param {string} newPassword - New Password
       * @param {string} confirmedPassword - Confirmed Password
       */
      $rootScope.$checkConfirmPassword = (newPassword, confirmedPassword) => {
        $rootScope.confirmInputCheck = '';
        if (confirmedPassword && confirmedPassword !== '' && newPassword === confirmedPassword) {
          $rootScope.confirmInputCheck = 'success';
          if ($rootScope.onCheckConfirmPassword) {
            $rootScope.onCheckConfirmPassword(true);
          }
        }
      };

      /**
       * Applies the error status if the conditions are met on blur
       * @param {string} newPassword - New Password
       * @param {string} confirmedPassword - Confirmed Password
       */
      $rootScope.$blurConfirm = (newPassword, confirmedPassword) => {
        if (confirmedPassword && newPassword !== confirmedPassword) {
          $rootScope.confirmInputCheck = 'error';
        }

        if ($rootScope.onCheckConfirmPassword) {
          $rootScope.onCheckConfirmPassword($rootScope.confirmInputCheck === 'success');
        }
      };

      /** ************************ */
      /** ******** MODAL ********* */
      /** ************************ */

      /** This handles the fade in of the modal */
      $rootScope.$openModal = (type, onOpen) => {
        $timeout(() => {
          $rootScope.showOverlay = true;
          $rootScope.showOverlayIndex = true;
        });

        $timeout(() => {
          $rootScope.showModalIndex = true;
          $rootScope.showModal = true;
          $rootScope.showModalType = type;
          $rootScope.overflowBody = true;
          if (onOpen) {
            onOpen();
          }
        }, 400);
      };

      /** This handles the fade out of the modal */
      $rootScope.$exitOverlay = (onExitF, onExit) => {
        if ($rootScope.$catchExitOverlay) {
          $rootScope.$catchExitOverlay();
          return;
        }

        if ($rootScope.$softCatchExitOverlay) {
          $rootScope.$softCatchExitOverlay();
          $rootScope.$softCatchExitOverlay = null;
        }

        if ($rootScope.showModal === true) {
          $rootScope.showModal = false;
          $timeout(() => {
            $rootScope.showModalIndex = false;
            $rootScope.showOverlay = false;
            $rootScope.showModalType = '';
            if (onExit) {
              onExit();
              return;
            }

            $timeout(() => {
              $rootScope.showOverlayIndex = false;
              $rootScope.overflowBody = false;
              if (onExitF) {
                onExitF();
              }
            }, 400);
          }, 400);
        }
      };

      /** ************************* */
      /** ******** BUBBLE ********* */
      /** ************************* */

      let lastBubbleEvent = null;
      $rootScope.$closeBubble = (event) => {
        const _event = (event || lastBubbleEvent);
        if (!_event) {
          return;
        }
        let $this = angular.element(_event.target);
        if (!$this) {
          return;
        }
        while ($this[0] && $this[0] && $this.parent() && !$this[0].__closeBubble) {
          $this = $this.parent();
        }
        if ($this && $this[0] && $this[0].__closeBubble) {
          $this[0].__closeBubble();
        }
      };

      /**
       * This function fades in or out the "Bubble" by either on focus or on click.
       * @param {{}} event - The element passed on by angular means.
       * @param {boolean} fadeIn - Determines whether or not to open the bubble or to close it.
       */
      $rootScope.$showBubble = (eventParam, fadeIn, isHover, dontClose) => {
        const event = eventParam || window.eventParam;
        lastBubbleEvent = event;

        if (isHover && fadeIn) {
          $rootScope.waitOrMouseLeave(
            () => {
              $rootScope.$showBubble(event, fadeIn, false);
            },
            250,
            angular.element(event.target)[0]
          );
          return undefined;
        }

        let inBubble = false;

        let bubbleLeave = true;

        let bubbleButtonLeave = true;
        let bubbleLeaveTO = null;
        let bubbleButtonLeaveTO = null;

        if ($rootScope.bubbleOpen) {
          if ($rootScope.bubbleClose) {
            $rootScope.bubbleClose();
          } else {
            event.preventDefault();
            event.stopPropagation();
            return false;
          }
        }

        // FRONTEND Should this be brought up higher?
        event.preventDefault();

        let $bubble = angular.element(event.target);

        const $this = angular.element(event.target);

        while (!$bubble.hasClass('item-container')) {
          $bubble = $bubble.parent();
        }
        $bubble = $bubble.next('.bubble-wrapper');

        const close = () => {
          inBubble = false;
          if ($this[0].__bmouseleave) {
            $this[0].removeEventListener('mouseleave', $this[0].__bmouseleave);
            $this[0].__bmouseleave = null;
          }

          if ($this[0].__bmouseleave2) {
            $bubble[0].removeEventListener('mouseleave', $this[0].__bmouseleave2);
            $this[0].__bmouseleave2 = null;
          }

          if ($this[0].__bmouseenter) {
            $bubble[0].removeEventListener('mouseenter', $this[0].__bmouseenter);
            $this[0].__bmouseenter = null;
          }

          if ($this[0].__bmouseenter0) {
            $this[0].removeEventListener('mouseenter', $this[0].__bmouseenter0);
            $this[0].__bmouseenter0 = null;
          }

          if ($this[0].__bclick) {
            $bubble[0].removeEventListener('mousedown', $this[0].__bclick);
            $this[0].__bclick = null;
          }

          $rootScope.bubbleOpen = false;
          $rootScope.bubbleClose = null;
          $bubble.removeClass('show-bubble');
          $this[0].__closeBubble = null;
          $bubble[0].__closeBubble = null;
          if (bubbleButtonLeaveTO !== null) {
            clearTimeout(bubbleButtonLeaveTO);
            bubbleButtonLeaveTO = null;
          }
          if (bubbleLeaveTO !== null) {
            clearTimeout(bubbleLeaveTO);
            bubbleLeaveTO = null;
          }
          if ($rootScope.$onBubbleClose) {
            $rootScope.$onBubbleClose();
            $rootScope.$onBubbleClose = null;
          }
          setTimeout(() => {
            $bubble.removeClass('pointer');
          }, 0.5 * 1000);
        };

        $this[0].__closeBubble = close;
        $bubble[0].__closeBubble = close;

        if (!fadeIn) {
          close();
          return undefined;
        }

        if (!dontClose) {
          $this[0].addEventListener(
            'mouseleave',
            ($this[0].__bmouseleave = (e) => {
              (e || window.event).preventDefault();
              bubbleButtonLeave = true;
              if (bubbleButtonLeaveTO !== null) {
                clearTimeout(bubbleButtonLeaveTO);
                bubbleButtonLeaveTO = null;
              }
              bubbleButtonLeaveTO = setTimeout(() => {
                bubbleButtonLeaveTO = null;
                if (bubbleButtonLeave) {
                  close();
                }
              }, BUBBLE_BUTTON_LEAVE_TIME * 1000);
              return false;
            })
          );

          $this[0].addEventListener(
            'mouseenter',
            ($this[0].__bmouseenter0 = (e) => {
              (e || window.event).preventDefault();
              if (bubbleButtonLeaveTO !== null) {
                clearTimeout(bubbleButtonLeaveTO);
                bubbleButtonLeaveTO = null;
              }

              return false;
            })
          );

          $bubble[0].addEventListener(
            'mouseenter',
            ($this[0].__bmouseenter = () => {
              inBubble = true;
              bubbleLeave = false;
              if (bubbleButtonLeaveTO !== null) {
                clearTimeout(bubbleButtonLeaveTO);
                bubbleButtonLeaveTO = null;
              }

              $bubble[0].addEventListener(
                'mousedown',
                ($this[0].__bclick = (ee) => {
                  const listnerEvent = ee || window.event;
                  listnerEvent.preventDefault();
                  listnerEvent.stopPropagation();
                  return false;
                })
              );

              $bubble[0].addEventListener(
                'mouseleave',
                ($this[0].__bmouseleave2 = (ee) => {
                  (ee || window.event).preventDefault();
                  bubbleLeave = true;
                  if (bubbleLeaveTO !== null) {
                    clearTimeout(bubbleLeaveTO);
                    bubbleLeaveTO = null;
                  }
                  bubbleLeaveTO = setTimeout(() => {
                    bubbleLeaveTO = null;
                    if (inBubble && bubbleLeave) {
                      close();
                    }
                  }, BUBBLE_LEAVE_TIME * 1000);
                  return false;
                })
              );
            })
          );
        }

        if ($bubble.hasClass('force-quit')) {
          $bubble.removeClass('force-quit');
        }

        $bubble.addClass('show-bubble');
        $bubble.addClass('pointer');

        $rootScope.bubbleOpen = true;
        $rootScope.bubbleClose = () => {
          close();
        };

        return false;
      };

      /**
       * This function fades in or out the "Bubble" by either on focus or on click.
       * @param {{}} event - The element passed on by angular means.
       * @param {boolean} fadeIn - Determines whether or not to open the bubble or to close it.
       */
      $rootScope.$showFixedBubble = (bubbleID, eventParam, fadeIn, isHover, dontClose) => {
        const event = eventParam || window.eventParam;

        if (isHover && fadeIn) {
          $rootScope.waitOrMouseLeave(
            () => {
              $rootScope.$showBubble(event, fadeIn, false);
            },
            250,
            angular.element(event.target)[0]
          );
          return undefined;
        }

        let bubbleButtonLeave = true;
        let bubbleLeaveTO = null;
        let bubbleButtonLeaveTO = null;

        if ($rootScope.bubbleOpen) {
          if ($rootScope.bubbleClose) {
            $rootScope.bubbleClose();
          } else {
            event.preventDefault();
            event.stopPropagation();
            return false;
          }
        }

        let inBubble = true;

        // FRONTEND Should this be brought up higher?
        event.preventDefault();

        let $anchor = angular.element(event.target);

        const $this = angular.element(event.target);

        let $bubbleWrap = angular.element(document.getElementById(bubbleID));

        const calcOffset = () => {
          /*let node = document.getElementById(bubbleID).parentNode;
          while (node) {
            const style = window.getComputedStyle(node, null);
            if (style && style.position === 'fixed') {
              const off = node.getBoundingClientRect();
              return {
                left: -off.left,
                top: -off.top
              };
            }
            node = node.parentNode;
          }*/
          return {
            left: 0, top: 0
          };
        };

        let pos = $anchor[0].getBoundingClientRect();
        let off = calcOffset();
        $bubbleWrap[0].style.left = `${pos.left + off.left}px`;
        $bubbleWrap[0].style.top = `${pos.top + off.top}px`;

        let $bubble = angular.element($bubbleWrap[0].childNodes[0]);

        let intID = setInterval(() => {
          pos = $anchor[0].getBoundingClientRect();
          let top = pos.top;
          /*if ((pos.top + $bubble[0].offsetHeight + 64) > window.innerHeight) {
            top = window.innerHeight - ($bubble[0].offsetHeight + 64);
          }*/
          off = calcOffset();
          $bubbleWrap[0].style.left = `${pos.left + off.left}px`;
          $bubbleWrap[0].style.top = `${top + off.top}px`;
          $bubbleWrap[0].style.minWidth = $anchor[0].offsetWidth + 'px';
          $bubbleWrap[0].style.minHeight = $anchor[0].offsetHeight + 'px';
        }, 1000/60);

        $bubbleWrap[0].style.minWidth = $anchor[0].offsetWidth + 'px';
        $bubbleWrap[0].style.minHeight = $anchor[0].offsetHeight + 'px';
        $bubbleWrap[0].style.width = $anchor[0].offsetWidth + 'px';
        $bubbleWrap[0].style.height = $anchor[0].offsetHeight + 'px';
        $bubbleWrap[0].style.display = 'block';

        if ($bubbleWrap[0].__bmouseleave) {
          $bubbleWrap[0].removeEventListener('mouseleave', $bubbleWrap[0].__bmouseleave);
          $bubbleWrap[0].__bmouseleave = null;
        }

        if ($bubbleWrap[0].__bmouseenter) {
          $bubbleWrap[0].removeEventListener('mouseenter', $bubbleWrap[0].__bmouseenter);
          $bubbleWrap[0].__bmouseenter = null;
        }

        if ($bubbleWrap[0].__bclick) {
          $bubble[0].removeEventListener('mousedown', $bubbleWrap[0].__bclick);
          $bubbleWrap[0].__bclick = null;
        }

        if ($bubbleWrap[0].__bmouseup) {
          $bubbleWrap[0].removeEventListener('mouseup', $bubbleWrap[0].__bmouseup);
          $bubbleWrap[0].__bmouseup = null;
        }

        $bubbleWrap[0].addEventListener(
          'mouseup',
          ($bubbleWrap[0].__bmouseup = (ee) => {
            const listnerEvent = ee || window.event;
            listnerEvent.preventDefault();
            listnerEvent.stopPropagation();
            return false;
          })
        );

        let close = () => {
          if (intID >= 0) {
            clearInterval(intID);
            intID = -1;
          }

          inBubble = false;
          if ($bubbleWrap[0].__bmouseleave) {
            $bubbleWrap[0].removeEventListener('mouseleave', $bubbleWrap[0].__bmouseleave);
            $bubbleWrap[0].__bmouseleave = null;
          }

          if ($bubbleWrap[0].__bmouseenter) {
            $bubbleWrap[0].removeEventListener('mouseenter', $bubbleWrap[0].__bmouseenter);
            $bubbleWrap[0].__bmouseenter = null;
          }

          if ($bubbleWrap[0].__bclick) {
            $bubble[0].removeEventListener('mousedown', $bubbleWrap[0].__bclick);
            $bubbleWrap[0].__bclick = null;
          }

          if ($bubbleWrap[0].__bmouseup) {
            $bubbleWrap[0].removeEventListener('mouseup', $bubbleWrap[0].__bmouseup);
            $bubbleWrap[0].__bmouseup = null;
          }

          $rootScope.bubbleOpen = false;
          $rootScope.bubbleClose = null;
          $bubble.removeClass('show-bubble');
          $bubble[0].__closeBubble = null;
          if (bubbleLeaveTO !== null) {
            clearTimeout(bubbleLeaveTO);
            bubbleLeaveTO = null;
          }
          if ($rootScope.$onBubbleClose) {
            $rootScope.$onBubbleClose();
            $rootScope.$onBubbleClose = null;
          }
          $bubble.removeClass('pointer');
          $bubbleWrap[0].style.display = 'none';
          close = null;
        };

        $bubble[0].__closeBubble = close;

        if (!fadeIn) {
          if (close) {
            close();
          }
          return undefined;
        }

        if (!dontClose) {
          $bubbleWrap[0].addEventListener(
            'mouseenter',
            ($bubbleWrap[0].__bmouseenter = () => {
              inBubble = true;
              if (bubbleLeaveTO !== null) {
                clearTimeout(bubbleLeaveTO);
                bubbleLeaveTO = null;
              }
            })
          );

          $bubbleWrap[0].addEventListener(
            'mouseleave',
            ($bubbleWrap[0].__bmouseleave = (ee) => {
              (ee || window.event).preventDefault();
              inBubble = false;
              if (bubbleLeaveTO !== null) {
                clearTimeout(bubbleLeaveTO);
                bubbleLeaveTO = null;
              }
              bubbleLeaveTO = setTimeout(() => {
                bubbleLeaveTO = null;
                if (!inBubble && close) {
                  close();
                }
              }, BUBBLE_LEAVE_TIME * 1000);
              return false;
            })
          );

          $bubbleWrap[0].__bmouseenter();
        }

        if ($bubble.hasClass('force-quit')) {
          $bubble.removeClass('force-quit');
        }

        $bubble.addClass('show-bubble');
        $bubble.addClass('pointer');

        $rootScope.bubbleOpen = true;
        $rootScope.bubbleClose = () => {
          inBubble = false;
          if (close) {
            close();
          }
        };

        return false;
      };

      /**
       * This function fades in or out the "Bubble" on hover
       * @param {{}} event - The element passed on by angular means.
       * @param {boolean} fadeIn - Determines whether or not to open the bubble or to close it.
       */
      $rootScope.$hoverBubble = (eventParam, fadeIn, chained) => {
        const event = eventParam || window.eventParam;
        if (!chained) {
          $rootScope.waitOrMouseLeave(
            () => {
              $rootScope.$hoverBubble(event, fadeIn, true);
            },
            250,
            angular.element(event.target)[0]
          );
        }

        if ($rootScope.bubbleOpen) {
          if ($rootScope.bubbleClose) {
            $rootScope.bubbleClose();
          } else {
            event.preventDefault();
            event.stopPropagation();
            return false;
          }
        }

        let $bubble = angular.element(event.target);

        const $this = angular.element(event.target);

        while (!$bubble.hasClass('item-container')) {
          $bubble = $bubble.parent();
        }
        $bubble = $bubble.next('.bubble-wrapper');

        const close = () => {
          if ($this[0].__bmouseleave) {
            $this[0].removeEventListener('mouseleave', $this[0].__bmouseleave);
            $this[0].__bmouseleave = null;
          }
          $this[0].__closeBubble = close;
          $bubble[0].__closeBubble = close;
          $bubble.removeClass('show-bubble');
          setTimeout(() => {
            $bubble.removeClass('pointer');
          }, 0.5 * 1000);
          $rootScope.bubbleOpen = false;
          $rootScope.bubbleClose = null;
          if ($rootScope.$onBubbleClose) {
            $rootScope.$onBubbleClose();
            $rootScope.$onBubbleClose = null;
          }
        };

        if (!fadeIn) {
          close();
          return undefined;
        }

        $this[0].__closeBubble = close;
        $bubble[0].__closeBubble = close;

        $this[0].addEventListener(
          'mouseleave',
          ($this[0].__bmouseleave = (e) => {
            e.preventDefault();
            close();
            return false;
          })
        );

        if ($bubble.hasClass('force-quit')) {
          $bubble.removeClass('force-quit');
        }

        $bubble.addClass('show-bubble');
        $bubble.addClass('pointer');

        $rootScope.bubbleOpen = true;
        $rootScope.bubbleClose = () => {
          close();
        };

        return undefined;
      };

      /** **************************** */
      /** ******** FUNCTIONS ********* */
      /** **************************** */
    };
  }
]);
