app.controller('resetPasswordController', [
  '$scope',
  '$state',
  '$rootScope',
  '$timeout',
  'userService',
  '$q',
  '$location',
  'initService',
  function($scope, $state, $rootScope, $timeout, userService, $q, $location, initService) {
    /** **************************** */
    /** ******** VARIABLES ********* */
    /** **************************** */

    /** ********************************* */
    /** ******** INIT FUNCTIONS ********* */
    /** ********************************* */
    initService.general();
    $rootScope.$globalLoader(false);

    /** *************************** */
    /** ******** Watchers ********* */
    /** *************************** */

    /** ********************************** */
    /** ******** SCOPE VARIABLES ********* */
    /** ********************************** */
    const initialState = 'resetPassword';
    /** The soft state */
    $rootScope.state = initialState;
    /** The soft state */
    $rootScope.upZIndex = initialState;

    /** ********************************** */
    /** ******** SCOPE FUNCTIONS ********* */
    /** ********************************** */
    $rootScope.onCheckNewPassword = function() {};
    $rootScope.onCheckConfirmPassword = function() {};
    /**
     * This is the reset password function
     * @param {string} newPassword - New Password.
     * @param {string} confirmedPassword - Confirmed Password.
     */
    $scope.resetPassword = function(newPassword, confirmedPassword) {
      $scope.loaderReset = true;
      return $q((resolve, reject) => (newPassword === confirmedPassword ? resolve() : reject()))
        .then(() =>
          userService
            .resetPassword(newPassword, $location.search().token)
            .then(() => {
              $rootScope.$goToStateChange('location', '/login?param=reset-password-success');
            })
            .catch(() => {
              $rootScope.$goToStateChange('location', '/login?param=reset-password-failed');
            })
        )
        .catch(() => {
          $rootScope.$goToStateChange('location', '/login?param=reset-password-failed');
        });
    };

    /** **************************** */
    /** ******** FUNCTIONS ********* */
    /** **************************** */
  }
]);
