app.service('userService', [
  '$rootScope',
  '$q',
  'usersService',
  '$http',
  '$location',
  function($rootScope, $q, usersService, $http, $location) {
    /** ************************************ */
    /** ******** SERVICE FUNCTIONS ********* */
    /** ************************************ */

    this.login = function(email, password) {
      return usersService.login({
        email,
        password
      });
    };

    this.logout = function() {
      return usersService.logout({});
    };

    this.forgotPassword = function(email) {
      return usersService.forgotPassword({
        email
      });
    };

    this.createAccount = function(firstName, lastName, phone, password) {
      $http.defaults.headers.common.Authorization = $location.search().token;
      return usersService.createAccount({
        first_name: firstName,
        last_name: lastName,
        phone,
        password
      });
    };

    this.getUserInformation = function() {
      return usersService.getUserInformation().then((res) => {
        if (res === 403) {
          return res;
        }

        if (window.DEMO_MODE === undefined && res.user.is_demo) {
          window.DEMO_MODE = true;
        }

        $rootScope.decodedToken = res;
        $rootScope.decodedToken.user.user_id = res.user.id;
        $rootScope.decodedToken.user.email_domain = res.user.email.split('@')[1];

        return null;
      });
    };

    this.updateUserInformation = function(obj) {
      return usersService.updateUserInformation(obj).then((res) => {
        if (res.errors) {
          return res;
        }

        $rootScope.decodedToken.user.email_domain = res.user.email.split('@')[1];
        $rootScope.decodedToken.user.user_id = res.user.id;

        return null;
      });
    };

    this.inviteUser = (email, first_name, last_name, org_admin_flag, billing_admin_flag, add_property_flag, share_all_buildings_flag) =>
      usersService.inviteUser({email, first_name, last_name, org_admin_flag, billing_admin_flag, add_property_flag, share_all_buildings_flag});

    this.removeOrgUser = (email) => usersService.removeOrgUser(email);

    this.updateOrgUser = (email, permissions) => usersService.updateOrgUser(email, permissions);

    this.getOrgUsers = () => usersService.getOrgUsers();

    this.updatePassword = function(obj) {
      return usersService.updatePassword(obj);
    };

    this.updateSettings = function(obj) {
      return usersService.updateSettings(obj);
    };

    this.resetPassword = function(password) {
      return usersService.resetPassword({ password });
    };

    this.getLogo = function() {
      return usersService.getLogo();
    };
  }
]);
