app.service('functionService', [
  '$rootScope',
  '$q',
  '$timeout',
  '$filter',
  'initService',
  function($rootScope, $q, $timeout, $filter, initService) {
    const functionService = this;

    /** **************************** */
    /** ******** VARIABLES ********* */
    /** **************************** */

    /** **************************** */
    /** ******** FUNCTIONS ********* */
    /** **************************** */

    /** ************************************ */
    /** ******** SERVICE FUNCTIONS ********* */
    /** ************************************ */

    this.createDynamicColumn = function(noClick) {
      return $q((resolve) => {
        for (let i = 0; i < $rootScope.filteredProperties.length; i++) {
          const property = $rootScope.filteredProperties[i];

          if ($rootScope.costPer === 'Estimated Annual Cost Per Area') {
            property.dynamic_column = property.avg_annual_cost_per_area;
            $rootScope.portfolioDynamicFilterName = 'Cost Per Area';
          } else if ($rootScope.costPer === 'Estimated Annual Energy Use Intensity') {
            property.dynamic_column = property.usage_per_area;
            $rootScope.portfolioDynamicFilterName = ' Energy Use Intensity';
          }
        }

        $rootScope.filteredProperties = $filter('orderBy')(
          $rootScope.filteredProperties,
          'dynamic_column',
          true
        );

        for (let i = 0; i < $rootScope.filteredProperties.length; i++) {
          const property = $rootScope.filteredProperties[i];
          property.rank = i + 1;
        }

        if ($rootScope.propertiesTable && !noClick) {
          $rootScope.propertiesTable.ths[1].children[0].click();
        }
        return resolve();
      });
    };

    this.round = function(value, decimals) {
      return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
    };
  }
]);
