app.controller('registrationController', [
  '$scope',
  '$state',
  '$rootScope',
  '$timeout',
  'userService',
  'storageService',
  '$q',
  'initService',
  '$http',
  function ($scope, $state, $rootScope, $timeout, userService, storageService, $q, initService, $http) {
    /** **************************** */
    /** ******** VARIABLES ********* */
    /** **************************** */
    /** $('.phone-input') */
    const phoneInput = $('.phone-input');
    /** 'welcome' */
    const initialState = 'welcome';
    /** Boolean value on whether or not the phone drop down is open */
    let dropdownOpen = false;
    /** Boolean value on whether or not the phone input has focus */
    let phoneFocus = false;

    $rootScope.onCheckNewPassword = function() {};
    $rootScope.onCheckConfirmPassword = function() {};

    /** ********************************** */
    /** ******** SCOPE VARIABLES ********* */
    /** ********************************** */
    /** The soft state */
    $rootScope.state = initialState;
    /** The soft state */
    $rootScope.upZIndex = initialState;
    /** This checks to see if this passes as part of the requirement for the disabled submit button. Note: True = Good */
    $scope.lastPhoneDisCheck = true;

    /** Init form value based on decoded token */
    const token = $rootScope.decodedToken;
    if (token && token.user) {
      $scope.firstName = token.user.first_name || '';
      $scope.lastName = token.user.last_name || '';
      $scope.firstNameCheck = !$scope.firstName.trim().length ? undefined : 'success';
      $scope.lastNameCheck = !$scope.lastName.trim().length ? undefined : 'success';

      window.Intercom('boot', {
        app_id: window.intercomAppID,
        user_id: token.user.id
      });
      window.Intercom('update', {
        app_id: window.intercomAppID,
        user_id: token.user.id,
        name: `${$scope.firstName || ''} ${$scope.lastName || ''}`
      });
      FS.identify(token.user.id, {
        displayName: `${token.user.first_name} ${token.user.last_name || ''}`,
        email: token.user.email
      });
    }

    /** **************************** */
    /** ******** FUNCTIONS ********* */
    /** **************************** */
    /**
     * Checks the phone number in a few fields then passes the information along.
     */
    function phoneNumberCheck() {
      return $q((resolve) => {
        /** This gets the phone number in a string format. */
        const intlNumber = phoneInput.intlTelInput('getNumber');
        /** This determines if the phone number meets the requirements of the format. Note: It returns a number which indicates the issue. 0 means Good. */
        const error = phoneInput.intlTelInput('getValidationError');
        /** This determines if this phone number is a valid one. */
        const isValid = phoneInput.intlTelInput('isValidNumber');
        return resolve({
          intlNumber,
          error,
          isValid
        });
      });
    }

    /**
     * This function assignes the appropriate error and success states to the input.
     * @param {{}} obj - This is an object that has been passed on from the phoneNumberCheck().
     */
    function phoneOutput(obj) {
      // console.log('Phone Number')
      // console.log(obj.intlNumber)

      $timeout(() => {
        if (obj.error === 0 && obj.isValid) {
          $scope.lastPhoneCheck = 'success';
          $scope.lastPhoneDisCheck = true;
        } else if (phoneInput.val() !== '') {
          $scope.lastPhoneDisCheck = false;
          $scope.lastPhoneCheck = 'error';
        } else {
          $scope.lastPhoneDisCheck = true;
        }
      });
    }

    /** ********************************* */
    /** ******** INIT FUNCTIONS ********* */
    /** ********************************* */
    $rootScope.$deviceDetection();
    moment.tz.setDefault();
    initService.general();
    $rootScope.$globalLoader(false);

    /**
     * This is the init for the intl-tel-input
     *
     * initialCountry --> Lookes up the user's country based on their IP address and makes that the default country code.
     * formatOnDisplay -->  Formats the input value.
     * geoIpLookup --> This is needed for initialCountry to work
     * utilsScript --> This is needed for formatOnDisplay to work
     *
     * This library can be found here --> https://github.com/jackocnr/intl-tel-input
     */
    phoneInput.intlTelInput({
      initialCountry: 'auto',
      formatOnDisplay: true,
      geoIpLookup(callback) {
        $.get('https://ipinfo.io', () => {}, 'jsonp').always((resp) => {
          const countryCode = resp && resp.country ? resp.country : '';
          callback(countryCode);
        });
      },
      utilsScript: '../vendors/utils.js'
    });

    /** *************************** */
    /** ******** Watchers ********* */
    /** *************************** */

    /** ********************************** */
    /** ******** SCOPE FUNCTIONS ********* */
    /** ********************************** */

    // FRONTEND-COMMENT
    $scope.createState = (type) => {
      $rootScope.$stateChange(type);
      $('input[name=firstName]').focus();

      /** This triggers when the country dropdown is activated (Open) */
      phoneInput.on('open:countrydropdown', () => {
        dropdownOpen = true;
      });

      /** This triggers when the country dropdown is un-activated (Closed) */
      phoneInput.on('close:countrydropdown', () => {
        dropdownOpen = false;
        setTimeout(() => {
          if (!phoneFocus) {
            phoneNumberCheck().then((res) => {
              phoneOutput(res);
            });
          }
        }, 250);
      });

      /** This triggers when the country has changed from the dropdown */
      phoneInput.on('countrychange', () => {
        if ($scope.lastPhoneCheck === 'success') {
          $timeout(() => {
            $scope.lastPhoneCheck = '';
            $scope.lastPhoneDisCheck = false;
          });
        } else {
          phoneNumberCheck().then((res) => {
            phoneOutput(res);
          });
        }
      });

      /** This triggers when the phone input has focus */
      phoneInput.focus(() => {
        phoneFocus = true;
      });

      /** This triggers when the phone input has been blured */
      phoneInput.blur(() => {
        phoneFocus = true;
        setTimeout(() => {
          if (!dropdownOpen) {
            phoneNumberCheck().then((res) => {
              phoneOutput(res);
            });
          }
        }, 250);
      });

      /** This triggers when there is a key up in the phone input */
      phoneInput.keyup(() => {
        phoneNumberCheck().then((res) => {
          phoneInput.intlTelInput('setNumber', res.intlNumber);
          $timeout(() => {
            if (res.error === 0 && res.isValid) {
              $scope.lastPhoneCheck = 'success';
              $scope.lastPhoneDisCheck = true;
            } else {
              $scope.lastPhoneDisCheck = phoneInput.val() === '';
              $scope.lastPhoneCheck = '';
            }
          });
        });
      });
    };

    /**
     * This assignes a success or error status depending on the condition
     * @param {string} name - User's first name.
     */
    $scope.blurFirstName = function(name) {
      $scope.firstNameCheck = !(name || '').trim().length ? 'error' : 'success';
    };

    /**
     * This assignes a success or error status depending on the condition
     * @param {string} name - User's last name.
     */
    $scope.blurLastName = function(name) {
      $scope.lastNameCheck = !(name || '').trim().length ? 'error' : 'success';
    };

    /**
     * This is the Create Account Function.
     * @param {string} firstName - User's first name.
     * @param {string} lastName - User's last name.
     * @param {number} phone - User's phone number.
     * @param {string} password - User's password.
     */
    $scope.createAcccount = function(firstName, lastName, phone, password) {
      if (!$scope.phoneError) {
        /** This gets the phone number in a string format. */
        // const intlNumber = phoneInput.intlTelInput("getNumber");

        /** This gives us information on the country's area code. */
        const countryData = phoneInput.intlTelInput('getSelectedCountryData');
        const phoneVal = phoneInput.val();
        let phoneNumber;

        if (phoneVal === '') {
          phoneNumber = null;
        } else {
          phoneNumber = `${countryData.dialCode} ${phoneVal}`;
        }

        $scope.loaderLogin = true;

        return userService
          .createAccount(firstName, lastName, phoneNumber, password)
          .then((obj) => {
            if (window.ITOKEN) {
              window.location.href = `/accept-building-invite?token=${window.ITOKEN}&no-logout=1`;
              return;
            }

            $rootScope.token = `${obj}`;
            sessionStorage.token = `${obj}`;
            $http.defaults.headers.common.Authorization = `${obj}`;
            document.cookie = `token=${obj}`;

            return storageService
              .decodeToken(obj)
              .then((res) => {
                $rootScope.decodedToken = res;
              })
              .then(() => userService.getUserInformation())
              .then(() => {
                if ($rootScope.decodedToken && !window.NO_REDIRECT) {
                  window.location.href = `${$rootScope.decodedToken.org.dashboard_url}/buildings`;
                } else {
                  window.location.href = '/buildings';
                }
              });
          })
          .catch(() => {
            $rootScope.$goToStateChange('location', '/login?param=registration-creation-error');
          });
      }
      return undefined;
    };
  }
]);
