app.service('initService', [
  '$rootScope',
  '$parse',
  '$timeout',
  function($rootScope, $parse, $timeout) {
    /** ************************************ */
    /** ******** SERVICE FUNCTIONS ********* */
    /** ************************************ */

    this.general = function() {
      $timeout(() => {
        $rootScope.propertySearch = $rootScope.propertySearch;
      });

      /**
       * This detects cap locks on keypress on password inputs.
       * This function needs to be called in the init of a controller that has a password input.
       */
      const passwordWrapper = document.getElementsByClassName('password-wrapper');
      for (let i = 0; i < passwordWrapper.length; i++) {
        const element = passwordWrapper[i];
        element.prevCapsState = false;
        // This is a check for if the user is typing in the login page and has there caps lock on
        element.addEventListener('keypress', (event) => {
          const keyCode = event.which; // get keycode
          const isUp = !!(keyCode >= 65 && keyCode <= 90); // uppercase
          const isLow = !!(keyCode >= 97 && keyCode <= 122); // lowercase
          const isShift = event.shiftKey ? event.shiftKey : keyCode === 16; // shift is pressed

          // uppercase w/out shift or lowercase with shift === caps lock
          element.activatedCaps = !!((isUp && !isShift) || (isLow && isShift));

          if (element.prevCapsState !== element.activatedCaps) {
            if (element.activatedCaps) {
              element.classList.add('activated-caps');
            } else {
              element.classList.remove('activated-caps');
            }
          }

          element.prevCapsState = element.activatedCaps;
        });
      }

      /**
       * This will add a cancel icon to the input when it holds a value.
       * Each input will function seperately from the rest.
       * This function needs to be called in the init of a controller that has search bar input.
       */
      /** This will get array of elements with has the class name search-input. */
      const searchInputs = document.getElementsByClassName('search-input');
      /** Iterate over the elements for the purpose of adding information to the elements object. */
      for (let i = 0; i < searchInputs.length; i++) {
        const element = searchInputs[i];
        /** This variable holds the .cancel-icon element. */
        const cancelDiv = element.children[2].children[0];
        /** This sets a reference to the element inside .cancel-icon */
        cancelDiv.searchInput = element;

        element.addEventListener('keyup', function() {
          const child = this.children[0];
          if (child.value !== '') {
            if ($rootScope[child.name] !== undefined) {
              $timeout(() => {
                $rootScope[child.name] = child.value;
              });
            } else {
              $rootScope.$assignRootScopeVar(child, child.value);
            }
            this.classList.add('cancel-option');
          } else {
            $timeout(() => {
              $rootScope.$assignRootScopeVar(child, false);
            });
            this.classList.remove('cancel-option');
          }
        });
      }
    };

    const labelClick = (e) => {
      const label = e.currentTarget;
      /** This holds the name of the .th of the label that was clicked on. */
      const thName = label.parent_th.getAttribute('name');
      /** This holds the element .table of the label that was clicked on. */
      if (!thName) {
        e.preventDefault();
        return false;
      }
      const table = label.parent_table;
      const tableName = table.getAttribute('name');

      /** If the name of the .th of the label that was clicked on is the same as the the current tables column filter. */
      if (thName === $rootScope[tableName].column) {
        if ($rootScope[tableName].down_direction) {
          label.classList.remove('up');
        } else {
          label.classList.add('up');
        }

        $timeout(() => {
          $rootScope[tableName].down_direction = !$rootScope[tableName].down_direction;
        });
      } else {
        /** clears all selected labels */
        for (let i = 0; i < $rootScope[tableName].ths.length; i++) {
          const lb = $rootScope[tableName].ths[i].children[0];
          if (lb.classList.contains('selected')) {
            lb.classList.remove('selected');
            lb.classList.remove('up');
          }
        }
        const ths = $rootScope[tableName].ths;
        label.classList.add('selected');

        $timeout(() => {
          /** Updates the filter variable */
          $rootScope[tableName] = {
            column: thName,
            label,
            down_direction: false,
            ths
          };
        });
      }
      return undefined;
    };

    // FRONTEND I think the direction of the arrows are mixed up.
    // FRONTEND Turn Label into th
    /**
     * When called, this function will iterate over tables that will have the column filtering system and hook it up.
     * @param {string} initFilter - This is the init column filter by name. The value will be the name of the ".th label"
     */
    this.tableColumnFilter = function(tableId, initFilter, inInitDown) {
      /**
       * This object is binded with the .table element.
       * The "column_filter" key is added at a later time and it contains a hash ( The name of the .th : The element .th ).
       */

      let initDown = inInitDown;
      const table = document.getElementById(tableId);
      /** This is an array of all the .th within a .table */
      const ths = table.children[0].children;
      /** Name of the table element. */
      let tableName = table.getAttribute('name');
      tableName = $parse(tableName);
      table.column_filter = {};

      const callAfter = (label, thName) => () => {
        if (initDown) {
          label.classList.add('up');
        } else {
          initDown = false;
        }

        /** Creates a $rootScope with the name of the .table as its name */
        tableName.assign($rootScope, {
          column: thName,
          label,
          down_direction: initDown,
          ths
        });
      };

      for (let i = 0; i < ths.length; i++) {
        const th = ths[i];
        const thName = th.getAttribute('name');

        if (!thName) {
          const label = th.children[0];
          $(label).addClass('col-no-filter');
          continue;
        }

        table.column_filter[thName] = th;
        /**
         * This object is binded with the label element.
         * The "parent_table" key will contain the binded element table.
         * The "parent_th"  key will contain the binded element .th.
         */
        const label = th.children[0];
        const alreadyListen = !!label.parent_table;
        label.parent_table = table;
        label.parent_th = th;

        /** This finds the init column filter. */
        if (initFilter === thName) {
          label.classList.add('selected');
          $timeout(callAfter(label, thName));
        }

        if (!alreadyListen) {
          /** Adds a personalised event listener to all labels inside .th's. */
          label.addEventListener('click', labelClick);
        }
      }
    };

    /** **************************** */
    /** ******** FUNCTIONS ********* */
    /** **************************** */
  }
]);
