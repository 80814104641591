app.controller('deviceDetectedController', [
  '$scope',
  '$rootScope',
  function($scope, $rootScope) {
    /** **************************** */
    /** ******** VARIABLES ********* */
    /** **************************** */

    /** ********************************** */
    /** ******** SCOPE VARIABLES ********* */
    /** ********************************** */

    /** ********************************* */
    /** ******** INIT FUNCTIONS ********* */
    /** ********************************* */

    /** *************************** */
    /** ******** Watchers ********* */
    /** *************************** */

    /** ********************************** */
    /** ******** SCOPE FUNCTIONS ********* */
    /** ********************************** */
    $scope.goTo = (type) => {
      $rootScope.$exitOverlay(null, () => {
        $rootScope.$openModal(type);
      });
    };

    /** **************************** */
    /** ******** FUNCTIONS ********* */
    /** **************************** */
  }
]);
